import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Table } from 'react-bootstrap';

import { formatDateTime } from '../util/dates';

const Td = styled.td.attrs({
  className: 'align-baseline',
})``;

/** only shown when there's "extra" room */
export const ExtraTd = styled(Td).attrs({
  // className: 'text-muted small d-none d-md-table-cell pl-5'
  className: 'text-muted small d-none d-md-table-cell',
})``;

const extraColumns = [
  {
    label: 'ID',
    fn: (d) => <tt>{d.id}</tt>,
    as: ExtraTd,
    className: 'pl-5',
  },
  {
    label: 'Created',
    fn: (d) => formatDateTime(d.createdAt),
    as: ExtraTd,
  },
  {
    label: 'Updated',
    fn: (d) => formatDateTime(d.updatedAt),
    as: ExtraTd,
  },
];

/** Tabular data, to make it easier to create consistent tables.  Additional properties are passed to the underlying Table */
export default function DataTable({
  columns: columnsProp,
  standardExtraColumns,
  data,
  extraRow,
  hover,
  children,
  ...tableProps
}) {
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    setColumns(
      standardExtraColumns ? columnsProp.concat(extraColumns) : columnsProp,
    );
  }, [columnsProp, standardExtraColumns]);

  if (children) {
    console.warn('unexpected children for DataTable!');
    throw new Error('children not expected for DataTable');
  }

  return (
    <Table hover={hover} {...tableProps}>
      <thead>
        <tr>
          {columns.map((col, c) => {
            const Cell = col.as ?? Td;
            return (
              <Cell key={c} as="th" className={col.className}>
                {col.label}
              </Cell>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {data.map((item, i) => (
          <tr key={i}>
            {columns.map((col, c) => {
              const Cell = col.as ?? Td;
              return (
                <Cell key={c} className={col.className}>
                  {col.fn(item)}
                </Cell>
              );
            })}
          </tr>
        ))}
        {extraRow &&
          columns.map((col, c) => {
            const Cell = col.as ?? Td;
            return (
              <Cell key={c} className={col.className}>
                {extraRow[c]}
              </Cell>
            );
          })}
      </tbody>
    </Table>
  );
}

DataTable.propTypes = {
  /** column definitions for the data table */
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      /** the label to show for the column */
      label: PropTypes.string.isRequired,
      /** custom element (e.g. from styled-components); must act like 'td' */
      as: PropTypes.elementType,
      /** custom className to pass to 'td' */
      className: PropTypes.string,
      /** the data-extractor for the column, passed the row item */
      fn: PropTypes.func.isRequired,
    }),
  ).isRequired,

  /** whether to show standard ID, Created At, and Updated At columns */
  standardExtraColumns: PropTypes.bool,

  /** the array of item/row data */
  data: PropTypes.array,

  /**
   * an array of cell values for an extra row... the 'fn' is *not* called for
   * this
   */
  extraRow: PropTypes.array,

  /** hover passed to underlying Table */
  hover: PropTypes.bool,
};

DataTable.defaultProps = {
  hover: true,
  standardExtraColumns: false,
};
