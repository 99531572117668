/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type SearchDogsResultTitles_item$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type SearchDogsResultItem_item$ref: FragmentReference;
declare export opaque type SearchDogsResultItem_item$fragmentType: SearchDogsResultItem_item$ref;
export type SearchDogsResultItem_item = {|
  +dogId: ?string,
  +registeredName: ?string,
  +callName: ?string,
  +registrations: ?string,
  +prefixes: ?string,
  +suffixes: ?string,
  +dog: ?{|
    +titles: {|
      +totalCount: number
    |}
  |},
  +$fragmentRefs: SearchDogsResultTitles_item$ref,
  +$refType: SearchDogsResultItem_item$ref,
|};
export type SearchDogsResultItem_item$data = SearchDogsResultItem_item;
export type SearchDogsResultItem_item$key = {
  +$data?: SearchDogsResultItem_item$data,
  +$fragmentRefs: SearchDogsResultItem_item$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SearchDogsResultItem_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dogId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registeredName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "callName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registrations",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "prefixes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "suffixes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Dog",
      "kind": "LinkedField",
      "name": "dog",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TitlesConnection",
          "kind": "LinkedField",
          "name": "titles",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalCount",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SearchDogsResultTitles_item"
    }
  ],
  "type": "SearchDogsResult",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '5f4bae188c549358dfe2ac91135d7970';

module.exports = node;
