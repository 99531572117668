import React from 'react';
// import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import { NavDropdown } from 'react-bootstrap';

/** Admin-specific menu (navigation for admin pages) */
export default function NavMenuAdmin() {
  return (
    <NavDropdown title="Admin">
      <NavDropdown.Item as={Link} to="/admin/statistics">
        Statistics
      </NavDropdown.Item>
      <NavDropdown.Item as={Link} to="/admin/maintenance">
        Maintenance
      </NavDropdown.Item>

      <NavDropdown.Divider />

      <NavDropdown.Item as={Link} to="/admin/colors">
        Colors
      </NavDropdown.Item>
      <NavDropdown.Item as={Link} to="/admin/locations">
        Locations
      </NavDropdown.Item>
      <NavDropdown.Item as={Link} to="/admin/registries">
        Registries
      </NavDropdown.Item>
      <NavDropdown.Item as={Link} to="/admin/users">
        Users
      </NavDropdown.Item>

      <NavDropdown.Divider />

      <NavDropdown.Item as={Link} to="/admin/changelog">
        Changelog
      </NavDropdown.Item>
      <NavDropdown.Item as={Link} to="/admin/tradingcards">
        Trading Cards
      </NavDropdown.Item>
    </NavDropdown>
  );
}
