import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Link, NavLink } from 'react-router-dom';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faBell } from '@fortawesome/free-solid-svg-icons';

import { AppContext } from '../util/AppContext';

import NavMenuAdmin from './NavMenuAdmin';
import NavMenuUser from './NavMenuUser';

// simple wrapper to make the navbar easier to read
function NavItemLink(props) {
  return (
    <Nav.Item>
      <NavLink className="nav-link" {...props} />
    </Nav.Item>
  );
}

// TODO: get colors from theme provider!
const NavLogo = styled.svg`
  fill: hsl(195, 5%, 90%);
  height: 30px;
`;

/** The main navbar for the app. */
export default function MainNav({ onSignOut }) {
  const { appInfo, currentRole, currentUser } = useContext(AppContext);
  const { name: appName, Logo: AppLogo } = appInfo;

  const showAdmin = currentRole === 'pedigree_admin';
  const showUser = showAdmin || currentRole === 'pedigree_user';

  return (
    <Navbar expand="lg" bg="primary" variant="dark" sticky="top">
      {/*
          We don't use NavbarBrand because we want react-router's link
          handling.  It's only the 'navbar-brand' class on a link,
          though, so we just do that ourselves.
        */}
      <Link className="navbar-brand" to="/">
        <NavLogo as={AppLogo} /> {appName}
      </Link>
      <Navbar.Toggle onClick={null /* this.toggleNav */} />
      <Navbar.Collapse>
        <Nav className="mr-auto">
          <NavItemLink to="/" exact={true}>
            Search
          </NavItemLink>

          {/* we want active highlighting, but not click-handling */}
          <NavItemLink
            to="/dogs"
            disabled
            className="nav-link"
            style={{ pointerEvents: 'none', cursor: 'default' }}
          >
            Dogs
          </NavItemLink>

          <NavItemLink to="/kennels">Kennels</NavItemLink>
          <NavItemLink to="/about">About</NavItemLink>
        </Nav>
        <Nav>
          {showAdmin && <NavMenuAdmin />}

          {showUser && (
            <>
              <NavItemLink
                to="/notifications"
                title="Notifications (TBD)"
                disabled
              >
                <FontAwesomeIcon icon={faBell} />
              </NavItemLink>

              <NavDropdown title={<FontAwesomeIcon icon={faPlus} />} alignRight>
                <NavDropdown.Item disabled>New dog</NavDropdown.Item>
                <NavDropdown.Item disabled>
                  New <i>(something)</i>
                </NavDropdown.Item>

                <NavDropdown.Divider />

                <NavDropdown.Header>This dog?</NavDropdown.Header>
                <NavDropdown.Item disabled>
                  New <i>(issue?)</i>
                </NavDropdown.Item>

                <NavDropdown.Divider />

                <NavDropdown.Header>Admin only?</NavDropdown.Header>
                <NavDropdown.Item disabled>
                  New <i>(user?)</i>
                </NavDropdown.Item>
              </NavDropdown>
            </>
          )}

          <NavMenuUser currentUser={currentUser} onSignOut={onSignOut} />
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

MainNav.propTypes = {
  onSignOut: PropTypes.func.isRequired,
};
// export default (withRouter(MainNav));
