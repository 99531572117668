import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFragment } from 'relay-hooks';
import graphql from 'babel-plugin-relay/macro';

import Pagination from 'react-bootstrap/Pagination';

/**
 * Pagination block for search results.
 */
export default function SearchPagination({
  results: resultsProp,
  currentPage,
  perPage,
  onPage,
}) {
  const results = useFragment(
    graphql`
      fragment SearchPagination_results on SearchDogsResultsConnection {
        totalCount
      }
    `,
    resultsProp,
  );

  // We limit ourselves to an abbreviated set of pages when there are more than
  // seven: [1, 2, 3, 4, ..., 9], [1, ..., 4, 5, 6, ..., 9], [1, ..., 6, 7, 8]
  const [pageNums, setPageNums] = useState([]);

  useEffect(() => {
    const totalCount = results?.totalCount ?? 0;
    const pageCount = Math.ceil(totalCount / perPage);

    if (pageCount <= 1) {
      setPageNums([]);
      return;
    }

    const pageNums = [1];

    if (pageCount <= 7) {
      for (let p = 2; p < pageCount; p++) {
        pageNums.push(p);
      }
    } else {
      // push the three pages around the current page with leading/trailing
      // ellipses as needed.
      let start = currentPage - 1;
      let end = currentPage + 1;
      let startEllipsis = true;
      let endEllipsis = true;

      if (start <= 3) {
        start = 2;
        end = 5;
        startEllipsis = false;
      }

      if (end >= pageCount - 2) {
        end = pageCount - 1;
        start = end - 3;
        endEllipsis = false;
      }

      if (startEllipsis) {
        pageNums.push('...');
      }

      for (let p = start; p <= end; p++) {
        pageNums.push(p);
      }

      if (endEllipsis) {
        pageNums.push('...');
      }
    }

    pageNums.push(pageCount);

    setPageNums(pageNums);
  }, [results, currentPage, perPage]);

  const handlePageChange = useCallback(
    (event, page) => {
      event.preventDefault();
      // console.log(`PAGINATION CLICK: page ${page}`);
      if (onPage) {
        onPage(page);
      }
    },
    [onPage],
  );

  if (pageNums.length === 0) {
    return null;
  }

  return (
    <Pagination className="mt-4">
      {pageNums.map((p, i) => {
        if (p === '...') {
          return <Pagination.Ellipsis key={`ellipsis-${i}`} disabled />;
        }

        return (
          <Pagination.Item
            key={p}
            active={p === currentPage}
            onClick={(evt) => handlePageChange(evt, p)}
          >
            {p}
          </Pagination.Item>
        );
      })}
    </Pagination>
  );
}

SearchPagination.propTypes = {
  /** fulfilled by Relay fragment */
  results: PropTypes.object,
  /** the current (active) page number */
  currentPage: PropTypes.number.isRequired,
  /** the number of results per page */
  perPage: PropTypes.number.isRequired,
  /** callback fired when a page is requested */
  onPage: PropTypes.func.isRequired,
};
