/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type SearchPagination_results$ref: FragmentReference;
declare export opaque type SearchPagination_results$fragmentType: SearchPagination_results$ref;
export type SearchPagination_results = {|
  +totalCount: number,
  +$refType: SearchPagination_results$ref,
|};
export type SearchPagination_results$data = SearchPagination_results;
export type SearchPagination_results$key = {
  +$data?: SearchPagination_results$data,
  +$fragmentRefs: SearchPagination_results$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SearchPagination_results",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    }
  ],
  "type": "SearchDogsResultsConnection",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'e67bb15e49f4e3024a273db7c0f95561';

module.exports = node;
