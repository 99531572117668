/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RegistrationRedirector_registry_QueryVariables = {|
  registration: string,
  registry?: ?string,
|};
export type RegistrationRedirector_registry_QueryResponse = {|
  +dogs: {|
    +nodes: $ReadOnlyArray<{|
      +id: string,
      +registeredName: string,
      +registrations: ?$ReadOnlyArray<?{|
        +registry: ?string,
        +registration: ?string,
      |}>,
    |}>
  |}
|};
export type RegistrationRedirector_registry_Query = {|
  variables: RegistrationRedirector_registry_QueryVariables,
  response: RegistrationRedirector_registry_QueryResponse,
|};
*/


/*
query RegistrationRedirector_registry_Query(
  $registration: String!
  $registry: String
) {
  dogs: findDogsFromRegistration(registration: $registration, registry: $registry) {
    nodes {
      id
      registeredName
      registrations {
        registry
        registration
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "registration"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "registry"
  }
],
v1 = [
  {
    "alias": "dogs",
    "args": [
      {
        "kind": "Variable",
        "name": "registration",
        "variableName": "registration"
      },
      {
        "kind": "Variable",
        "name": "registry",
        "variableName": "registry"
      }
    ],
    "concreteType": "DogsConnection",
    "kind": "LinkedField",
    "name": "findDogsFromRegistration",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Dog",
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "registeredName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "RegistrationOutput",
            "kind": "LinkedField",
            "name": "registrations",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "registry",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "registration",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RegistrationRedirector_registry_Query",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RegistrationRedirector_registry_Query",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "60072682e04bae0f0ee645281dff276c",
    "id": null,
    "metadata": {},
    "name": "RegistrationRedirector_registry_Query",
    "operationKind": "query",
    "text": "query RegistrationRedirector_registry_Query(\n  $registration: String!\n  $registry: String\n) {\n  dogs: findDogsFromRegistration(registration: $registration, registry: $registry) {\n    nodes {\n      id\n      registeredName\n      registrations {\n        registry\n        registration\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '04c52e7ebf2c1a2744ff09832a48ae2c';

module.exports = node;
