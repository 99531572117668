import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Link, NavLink } from 'react-router-dom';
import { Image, Nav, NavDropdown } from 'react-bootstrap';

// simple wrapper to make the navbar easier to read
function NavItemLink(props) {
  return (
    <Nav.Item>
      <NavLink className="nav-link" {...props} />
    </Nav.Item>
  );
}

function MenuLink(props) {
  return <NavDropdown.Item as={Link} {...props} />;
}

/** User-specific menu (profile, settings, etc.) */
export default function NavMenuUser({ currentUser, onSignOut }) {
  const signOut = useCallback(
    (event) => {
      if (onSignOut) {
        event.preventDefault();
        onSignOut();
      }
    },
    [onSignOut],
  );

  if (!currentUser) {
    return <NavItemLink to="/login">Sign in</NavItemLink>;
  }

  const { id, firstName, fullName, gravatar } = currentUser;
  const userPage = `/users/${id}`;

  return (
    <NavDropdown
      alignRight
      title={
        <>
          {firstName}
          <Image
            rounded
            src={`https://www.gravatar.com/avatar/${gravatar}?d=identicon&s=24`}
            className="ml-2 mt-n1"
          />
        </>
      }
    >
      <MenuLink to={userPage}>
        Signed in as <b>{fullName}</b>
      </MenuLink>

      <NavDropdown.Divider />

      <MenuLink to={userPage}>Your profile</MenuLink>

      <NavDropdown.Divider />

      <MenuLink to="/help" disabled>
        Help (TBD)
      </MenuLink>

      <MenuLink to="/settings/profile">Settings</MenuLink>

      <MenuLink to="/" onClick={signOut}>
        Sign out
      </MenuLink>
    </NavDropdown>
  );
}

NavMenuUser.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    fullName: PropTypes.string,
    gravatar: PropTypes.string,
  }),
  onSignOut: PropTypes.func.isRequired,
};
