import React, { useCallback, useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { useQuery, useMutation } from 'relay-hooks';
import graphql from 'babel-plugin-relay/macro';
import { Link } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
// import Col from 'react-bootstrap/Col';
// import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';

import { Helmet } from 'react-helmet';

import smartquotes from 'smartquotes';
import styled from 'styled-components';

import { DateTime, Duration } from 'luxon';

import DebugDump from '../../components/DebugDump';
import { formatDateTime, humanizeDuration, relativize } from '../../util/dates';

const olderThanLimit = Duration.fromISO('P45D');
const olderThanDisplay = humanizeDuration(olderThanLimit, 'days');
const seedVsFileLimit = 20;

const SmallTd = styled.td.attrs((props) => ({
  className: `small align-baseline d-none d-md-table-cell ${props.className}`,
}))``;

const MutedTd = styled(SmallTd).attrs((props) => ({
  className: `text-muted ${props.className}`,
}))``;

function MaybeDeleted({ deleted, children }) {
  if (deleted) {
    return <del>{children}</del>;
  }

  return <>{children}</>;
}

const Pre = styled.pre.attrs({ className: 'alert alert-info' })``;

// simplifies the rendering?
function DogCells({
  id,
  mostRecent,
  registeredName,
  birthdate,
  fetchedAt,
  importedAt,
}) {
  const [mutate /* { loading, data, error } */] = useMutation(
    graphql`
      mutation Maintenance_deleteDog_Mutation($input: DeleteDogInput!) {
        deleteDog(input: $input) {
          clientMutationId
        }
      }
    `,
    {
      variables: { input: { id } },
    },
  );

  const [deleted, setDeleted] = useState(false);

  const handleDelete = useCallback(async () => {
    try {
      await mutate();
      setDeleted(true);
    } catch (error) {
      console.error('delete dog', error);
    }
  }, [mutate]);

  const LinkWrapper = deleted ? React.Fragment : Link;
  const linkProps = deleted ? {} : { to: `/dogs/${id}` };

  return (
    <>
      <td>
        <MaybeDeleted deleted={deleted}>
          <LinkWrapper {...linkProps}>
            <tt>{id}</tt>
          </LinkWrapper>
        </MaybeDeleted>
      </td>
      <td>
        <MaybeDeleted deleted={deleted}>
          <LinkWrapper {...linkProps}>
            {smartquotes(registeredName)}
          </LinkWrapper>
        </MaybeDeleted>
      </td>
      <SmallTd>
        <MaybeDeleted deleted={deleted}>
          {birthdate || <i>(none)</i>}
        </MaybeDeleted>
      </SmallTd>
      <MutedTd>
        <MaybeDeleted deleted={deleted}>
          {formatDateTime(fetchedAt)}
        </MaybeDeleted>
      </MutedTd>
      <MutedTd>
        <MaybeDeleted deleted={deleted}>
          {formatDateTime(importedAt)}
        </MaybeDeleted>
      </MutedTd>
      <td className="p-0">
        {!mostRecent && !deleted && (
          <Button onClick={handleDelete} variant="outline-danger mt-1">
            delete
          </Button>
        )}
      </td>
    </>
  );
}

export default function Maintenance() {
  const [until] = useState(() => DateTime.local().minus(olderThanLimit));

  const { props: queryResult, error: queryError } = useQuery(
    graphql`
      query Maintenance_Query($until: Datetime) {
        findDuplicates {
          totalCount
          nodes {
            zid
            count
            ids
            registeredNames
            birthdates
            fetchedAt
            importedAt
          }
        }
        leastRecentFetchedDogs(first: 10) {
          nodes {
            registeredName
            id
            zid
            fetchedAt
            importedAt
          }
        }
        olderThanXDays: leastRecentFetchedDogs(until: $until) {
          nodes {
            zid
          }
        }
      }
    `,
    {
      // variables
      until: until.toISO(),
    },
  );

  const [useSeedFile, setUseSeedFile] = useState();
  const [seedIds, setSeedIds] = useState([]);
  const [seedFileIds, setSeedFileIds] = useState([]);

  const { findDuplicates, leastRecentFetchedDogs, olderThanXDays } =
    queryResult ?? {};

  useEffect(() => {
    // We could attempt to minimize the seed file by collapsing ranges...
    const ids = olderThanXDays?.nodes.map(({ zid }) => zid) ?? [];
    setSeedIds(ids);
    if (ids.length > seedVsFileLimit) {
      setUseSeedFile(true);
      setSeedFileIds(minimizeIdRanges(ids));
    } else {
      setUseSeedFile(false);
    }
  }, [olderThanXDays]);

  return (
    <>
      <Helmet title="Maintenance" />
      <Container className="mt-4">
        <h1 className="mb-3">Maintenance</h1>

        {queryError ? (
          <>
            <div>Error: {queryError.message}</div>
            <div>Source: {JSON.stringify(queryError.source)}</div>
          </>
        ) : !queryResult ? (
          <div>Loading...</div>
        ) : (
          <>
            <h3>
              Duplicate ZIDs <small>({findDuplicates.totalCount})</small>
            </h3>
            <p>
              These are most likely dogs whose information has been updated in
              such a way that we were not able to verify they really were the
              same dog (different registered name <em>and</em> birthdate, for
              example).
            </p>
            <Table hover width="auto">
              <thead>
                <tr>
                  <th>ZID</th>
                  <th>Count</th>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Birthdate</th>
                  <th>Fetched</th>
                  <th>Imported</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {/* nested loop for duplicates */}
                {findDuplicates.nodes.length > 0 ? (
                  findDuplicates.nodes.map(
                    (
                      {
                        zid,
                        count,
                        ids,
                        registeredNames,
                        birthdates,
                        fetchedAt,
                        importedAt,
                      },
                      i,
                    ) => {
                      // sub-ids (etc.) are returned with most-recent first to
                      // least-recent last
                      return ids.map((id, j) => {
                        return (
                          <tr
                            key={`${zid}-${id}`}
                            className={j === 0 ? '' : 'table-danger'}
                          >
                            {j === 0 && <td rowSpan={count}>{zid}</td>}
                            {j === 0 && <td rowSpan={count}>{count}</td>}
                            <DogCells
                              mostRecent={j === 0}
                              id={id}
                              registeredName={registeredNames[j]}
                              birthdate={birthdates[j]}
                              fetchedAt={fetchedAt[j]}
                              importedAt={importedAt[j]}
                            />
                          </tr>
                        );
                      });
                    },
                  )
                ) : (
                  <tr>
                    <td colSpan="7">
                      <i>(No duplicate dogs found)</i>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>

            <h3 className="mt-4">Least-recently-fetched dogs</h3>
            <p>
              At times there are entries in the "imported dogs" table that get
              out-of-date, or otherwise disagree with the data in the
              fetch/import cache. They are detectable because they no longer
              update during import, leaving an older and older
              "least-recently-fetched" entry behind. The current hypothesis is
              that these are "deleted" dogs which are no longer tracked in the
              cache at all, and some past "delete the dog" path during import
              didn't properly clean things up. The best thing to do is to verify
              those dogs against the Source of Truth&trade;, then manually fetch
              and import them.
            </p>

            <h4>10 Least-recent</h4>
            <Table hover width="auto">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>ID</th>
                  <th>ZID</th>
                  <th>Fetched</th>
                  <th>Imported</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {leastRecentFetchedDogs.nodes.map(
                  ({ id, registeredName, zid, fetchedAt, importedAt }) => (
                    <tr key={id}>
                      <td>
                        <Link to={`/dogs/${id}`}>
                          {smartquotes(registeredName)}
                        </Link>
                      </td>
                      <td>
                        <tt className="align-baseline">{id}</tt>
                      </td>
                      <td>
                        <a
                          href={`http://www.pedigrees.zandebasenjis.com/cgi-bin/breeding.pl?op=breeding&index=${zid}&gens=5&db=basenji.dbw`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <tt className="align-baseline">{zid}</tt>
                        </a>
                      </td>
                      <td>
                        {formatDateTime(fetchedAt)}{' '}
                        <small className="text-muted">
                          ({relativize(fetchedAt)})
                        </small>
                      </td>

                      <td>
                        {formatDateTime(importedAt)}{' '}
                        <small className="text-muted">
                          ({relativize(importedAt)})
                        </small>
                      </td>
                    </tr>
                  ),
                )}
              </tbody>
            </Table>
            <p>Update the fetched information using:</p>
            <Pre>
              pedigree-fetcher --seed{' '}
              {leastRecentFetchedDogs.nodes.map(({ zid }) => zid).join(',')} |
              pino-pretty
              {'\n'}
              pedigree-importer | pino-pretty
            </Pre>

            <h4>Older than {olderThanDisplay}</h4>
            <p>
              If there are a significant number of import entries older than{' '}
              {olderThanDisplay}, it probably means that they are missing from
              the cache, and therefore not getting updated.
            </p>
            {seedIds.length === 0 ? (
              <>
                <p>There are no IDs older than {olderThanDisplay}!</p>
              </>
            ) : useSeedFile ? (
              <>
                <p>
                  There are {seedIds.length} IDs older than {olderThanDisplay},
                  so you likely want to use the <tt>--seed-file</tt> option:
                </p>
                <Pre>
                  cat &gt; seed-ids.txt &lt;&lt; EOF
                  {'\n'}
                  {seedFileIds.join('\n')}
                  {'\n'}
                  EOF
                  {'\n'}
                  pedigree-fetcher --seed-file seed-ids.txt | pino-pretty
                  {'\n'}
                  pedigree-importer | pino-pretty
                </Pre>
              </>
            ) : (
              <>
                <p>
                  There are only {seedIds.length} IDs older than{' '}
                  {olderThanDisplay}, so you can simply fetch these on the
                  command line:
                </p>
                <Pre>
                  pedigree-fetcher --seed {seedIds.join(',')} | pino-pretty
                  {'\n'}
                  pedigree-importer | pino-pretty
                </Pre>
              </>
            )}
            <DebugDump level={1} object={queryResult} />
          </>
        )}
      </Container>
    </>
  );
}

function minimizeIdRanges(ids) {
  const sorted = [...ids].sort((a, b) => a - b);
  const minimal = [];
  let start = null;
  let last = null;
  sorted.forEach((id) => {
    if (start === null) {
      start = id;
    } else if (id > last + 1) {
      minimal.push(last !== start ? `${start}-${last}` : `${last}`);
      start = id;
    }

    last = id;
  });

  // and catch the last value...
  minimal.push(last !== start ? `${start}-${last}` : `${last}`);
  return minimal;
}
