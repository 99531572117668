// take a plain query and turn it into a Postgres websearch_to_tsquery()-compliant
// string...
export function processQuery(query) {
  const words = splitQueryWords(query);
  return words.join(' ');
}

const naiveBreaker = /[^a-zA-Z0-9]+/g;

// const ignoredTerms = ['s', 'ch', 'gch'];
const ignoredTerms = ['s'];

// breaks a query into an array of simple strings
export function splitQueryWords(query) {
  // we omit everything other than alphabetic (and numeric?) characters for now;
  // if we add special operators later, we'll add them back in at that point.
  return query
    .split(naiveBreaker)
    .filter((s) => s !== '' && !ignoredTerms.includes(s));
}
