import React from 'react';

import { NavLink, Route, Switch } from 'react-router-dom';

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';

import Profile from './Profile';
import Tokens from './Tokens';

import DebugDump from '../../components/DebugDump';

function NavItem(props) {
  return <ListGroup.Item as={NavLink} action {...props} />;
}

export default function Settings(props) {
  return (
    <>
      <Container className="mt-4">
        <Row>
          <Col md={3}>
            <Card>
              <Card.Header as="h6">Personal settings</Card.Header>
              <ListGroup variant="flush">
                <NavItem to="/settings/profile">Profile</NavItem>
                <NavItem to="/settings/account">Account</NavItem>
                <NavItem to="/settings/security">Security</NavItem>
                <NavItem to="/settings/emails">Emails</NavItem>
                <NavItem to="/settings/notifications">Notifications</NavItem>
                <NavItem to="/settings/tokens">API Tokens</NavItem>
              </ListGroup>
            </Card>

            <div className="mt-5 p-2 bg-light border">
              <h5>TODO:</h5>
              <ul>
                <li>
                  <del>Show</del>/edit email
                </li>
                <li>
                  <del>Show</del>/manage tokens
                </li>
                <li>???</li>
              </ul>
            </div>
          </Col>
          <Col md={9}>
            <Switch>
              <Route path="/settings/profile" component={Profile} />
              <Route path="/settings/tokens" component={Tokens} />
            </Switch>
          </Col>
        </Row>
      </Container>
      <DebugDump level={2} object={props} />
    </>
  );
}
