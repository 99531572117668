/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type TradingCard_QueryVariables = {|
  id: string,
  depth: number,
|};
export type TradingCard_QueryResponse = {|
  +getDogAncestors: {|
    +nodes: $ReadOnlyArray<{|
      +dog: ?{|
        +id: string,
        +registeredName: string,
        +prefixes: ?$ReadOnlyArray<?string>,
        +suffixes: ?$ReadOnlyArray<?string>,
        +sireId: ?string,
        +damId: ?string,
        +awards: {|
          +nodes: $ReadOnlyArray<{|
            +granter: ?string,
            +name: ?string,
            +date: ?string,
            +display: ?string,
          |}>
        |},
      |}
    |}>
  |}
|};
export type TradingCard_Query = {|
  variables: TradingCard_QueryVariables,
  response: TradingCard_QueryResponse,
|};
*/


/*
query TradingCard_Query(
  $id: Id!
  $depth: Int!
) {
  getDogAncestors(id: $id, depth: $depth) {
    nodes {
      dog {
        id
        registeredName
        prefixes
        suffixes
        sireId
        damId
        awards {
          nodes {
            granter
            name
            date
            display
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "depth"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "depth",
        "variableName": "depth"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "AncestorResultsConnection",
    "kind": "LinkedField",
    "name": "getDogAncestors",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AncestorResult",
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Dog",
            "kind": "LinkedField",
            "name": "dog",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "registeredName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "prefixes",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "suffixes",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sireId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "damId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AwardInoutsConnection",
                "kind": "LinkedField",
                "name": "awards",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AwardInout",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "granter",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "date",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "display",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TradingCard_Query",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "TradingCard_Query",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "418b94885f14d61fe2c626507f250aaa",
    "id": null,
    "metadata": {},
    "name": "TradingCard_Query",
    "operationKind": "query",
    "text": "query TradingCard_Query(\n  $id: Id!\n  $depth: Int!\n) {\n  getDogAncestors(id: $id, depth: $depth) {\n    nodes {\n      dog {\n        id\n        registeredName\n        prefixes\n        suffixes\n        sireId\n        damId\n        awards {\n          nodes {\n            granter\n            name\n            date\n            display\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'faf00d8e8e112bcdd82202c7f1439063';

module.exports = node;
