/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type PedigreeTree_dog$ref = any;
type PedigreeTree_otherDog$ref = any;
export type PedigreeExplorer_Tree_QueryVariables = {|
  id: string,
  mateId: string,
  depth: number,
|};
export type PedigreeExplorer_Tree_QueryResponse = {|
  +thisDog: ?{|
    +$fragmentRefs: PedigreeTree_dog$ref
  |},
  +otherDog: ?{|
    +$fragmentRefs: PedigreeTree_otherDog$ref
  |},
|};
export type PedigreeExplorer_Tree_Query = {|
  variables: PedigreeExplorer_Tree_QueryVariables,
  response: PedigreeExplorer_Tree_QueryResponse,
|};
*/


/*
query PedigreeExplorer_Tree_Query(
  $id: Id!
  $mateId: Id!
  $depth: Int!
) {
  thisDog: dog(id: $id) {
    ...PedigreeTree_dog_1gqN78
  }
  otherDog: dog(id: $mateId) {
    ...PedigreeTree_otherDog_1gqN78
  }
}

fragment PedigreeTree_dog_1gqN78 on Dog {
  id
  sex
  ancestors(depth: $depth) {
    nodes {
      dog {
        id
        registeredName
        sex
        prefixes
        suffixes
        birthdate
        damId
        sireId
      }
    }
  }
}

fragment PedigreeTree_otherDog_1gqN78 on Dog {
  id
  sex
  ancestors(depth: $depth) {
    nodes {
      dog {
        id
        registeredName
        sex
        prefixes
        suffixes
        birthdate
        damId
        sireId
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "depth"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "mateId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v4 = [
  {
    "kind": "Variable",
    "name": "depth",
    "variableName": "depth"
  }
],
v5 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "mateId"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sex",
  "storageKey": null
},
v8 = [
  (v6/*: any*/),
  (v7/*: any*/),
  {
    "alias": null,
    "args": (v4/*: any*/),
    "concreteType": "AncestorResultsConnection",
    "kind": "LinkedField",
    "name": "ancestors",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AncestorResult",
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Dog",
            "kind": "LinkedField",
            "name": "dog",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "registeredName",
                "storageKey": null
              },
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "prefixes",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "suffixes",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "birthdate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "damId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sireId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PedigreeExplorer_Tree_Query",
    "selections": [
      {
        "alias": "thisDog",
        "args": (v3/*: any*/),
        "concreteType": "Dog",
        "kind": "LinkedField",
        "name": "dog",
        "plural": false,
        "selections": [
          {
            "args": (v4/*: any*/),
            "kind": "FragmentSpread",
            "name": "PedigreeTree_dog"
          }
        ],
        "storageKey": null
      },
      {
        "alias": "otherDog",
        "args": (v5/*: any*/),
        "concreteType": "Dog",
        "kind": "LinkedField",
        "name": "dog",
        "plural": false,
        "selections": [
          {
            "args": (v4/*: any*/),
            "kind": "FragmentSpread",
            "name": "PedigreeTree_otherDog"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "PedigreeExplorer_Tree_Query",
    "selections": [
      {
        "alias": "thisDog",
        "args": (v3/*: any*/),
        "concreteType": "Dog",
        "kind": "LinkedField",
        "name": "dog",
        "plural": false,
        "selections": (v8/*: any*/),
        "storageKey": null
      },
      {
        "alias": "otherDog",
        "args": (v5/*: any*/),
        "concreteType": "Dog",
        "kind": "LinkedField",
        "name": "dog",
        "plural": false,
        "selections": (v8/*: any*/),
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f3d9ac6971b511f199a07c2dc9b1d250",
    "id": null,
    "metadata": {},
    "name": "PedigreeExplorer_Tree_Query",
    "operationKind": "query",
    "text": "query PedigreeExplorer_Tree_Query(\n  $id: Id!\n  $mateId: Id!\n  $depth: Int!\n) {\n  thisDog: dog(id: $id) {\n    ...PedigreeTree_dog_1gqN78\n  }\n  otherDog: dog(id: $mateId) {\n    ...PedigreeTree_otherDog_1gqN78\n  }\n}\n\nfragment PedigreeTree_dog_1gqN78 on Dog {\n  id\n  sex\n  ancestors(depth: $depth) {\n    nodes {\n      dog {\n        id\n        registeredName\n        sex\n        prefixes\n        suffixes\n        birthdate\n        damId\n        sireId\n      }\n    }\n  }\n}\n\nfragment PedigreeTree_otherDog_1gqN78 on Dog {\n  id\n  sex\n  ancestors(depth: $depth) {\n    nodes {\n      dog {\n        id\n        registeredName\n        sex\n        prefixes\n        suffixes\n        birthdate\n        damId\n        sireId\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'faea18376fb40d1fc7629608d69ffd08';

module.exports = node;
