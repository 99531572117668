/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Tokens_QueryVariables = {||};
export type Tokens_QueryResponse = {|
  +profile: ?{|
    +role: ?string,
    +tokens: {|
      +nodes: $ReadOnlyArray<{|
        +createdAt: string,
        +role: string,
        +enabled: boolean,
        +revokedAt: ?string,
        +tokenHash: string,
      |}>
    |},
  |}
|};
export type Tokens_Query = {|
  variables: Tokens_QueryVariables,
  response: Tokens_QueryResponse,
|};
*/


/*
query Tokens_Query {
  profile: currentUser {
    role
    tokens {
      nodes {
        createdAt
        role
        enabled
        revokedAt
        tokenHash
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v1 = [
  {
    "alias": "profile",
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "currentUser",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "UserTokensConnection",
        "kind": "LinkedField",
        "name": "tokens",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserToken",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "enabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "revokedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tokenHash",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "Tokens_Query",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "Tokens_Query",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "01c47b58a096fa7ae3038397051cbe38",
    "id": null,
    "metadata": {},
    "name": "Tokens_Query",
    "operationKind": "query",
    "text": "query Tokens_Query {\n  profile: currentUser {\n    role\n    tokens {\n      nodes {\n        createdAt\n        role\n        enabled\n        revokedAt\n        tokenHash\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '853362c7547cd706feeb54b953a9bb07';

module.exports = node;
