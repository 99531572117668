import React from 'react';

import { Container } from 'react-bootstrap';

import ChangelogComponent from '../../components/Changelog';

export default function Changelog() {
  return (
    <Container className="mt-4 mb-4">
      <h1>Changelog</h1>
      <ChangelogComponent allowLinks />
    </Container>
  );
}
