import React from 'react';
import PropTypes from 'prop-types';
import { useFragment } from 'relay-hooks';
import graphql from 'babel-plugin-relay/macro';
import styled from 'styled-components';

import { Button, Form, Table } from 'react-bootstrap';

import {
  /*luxonify,*/ formatDateTimeFull /*formatDateFull*/,
} from '../util/dates';

import DogRegistrations from './DogRegistrations';
import DogTests from './DogTests';

function pedigreeLink(zid) {
  return `http://www.pedigrees.zandebasenjis.com/cgi-bin/geneal.pl?op=tree&index=${zid}&gens=5&db=basenji.dbw`;
}

function breedingLink(zid) {
  return `http://www.pedigrees.zandebasenjis.com/cgi-bin/breeding.pl?op=breeding&index=${zid}&gens=5&db=basenji.dbw`;
}

const ZandeRow = styled.tr.attrs({ className: 'bg-light' })`
  th,
  td {
    vertical-align: middle;
  }
`;

function ZandeButton({ href, ...props }) {
  return (
    <Button
      target="_blank"
      rel="noopener noreferrer"
      variant="outline-secondary"
      size="sm"
      className="ml-2"
      href={href}
      {...props}
    />
  );
}

/** The tabular presentation of dog details */
export default function DogDetails({ dog: dogProp }) {
  const dog = useFragment(
    graphql`
      fragment DogDetails_dog on Dog {
        callName
        sex
        birthdate
        color
        location
        ...DogRegistrations_dog
        ...DogTests_dog
        zid
        fetchedAt
        importedAt
      }
    `,
    dogProp,
  );

  return (
    <Table size="sm" style={{ width: 'auto' }}>
      <tbody>
        {dog.callName && (
          <tr>
            <th scope="row">call name:</th>
            <td>{dog.callName || '(unknown!)'}</td>
          </tr>
        )}
        <tr>
          <th scope="row">sex:</th>
          <td>{dog.sex || '(unknown!)'}</td>
        </tr>
        <tr>
          <th scope="row">birth date:</th>
          <td>{dog.birthdate || '(unknown)'}</td>
        </tr>
        <tr>
          <th scope="row">color:</th>
          <td>{dog.color || '(probably Red & White)'}</td>
        </tr>
        <tr>
          <th scope="row">location:</th>
          <td>{dog.location || '(unknown)'}</td>
        </tr>
        <DogRegistrations dog={dog} />
        <DogTests dog={dog} />
        {/*
          We *could* check AppContext.isAdmin before showing this, but we just
          rely on the server only returning a non-null zid when the user has the
          appropriate permissions.
        */}
        {dog.zid && (
          <>
            <ZandeRow>
              <th scope="row">
                <Form inline>
                  <Form.Label>zid:</Form.Label>
                </Form>
              </th>
              <td>
                <Form inline>
                  {dog.zid}
                  <ZandeButton href={breedingLink(dog.zid)}>source</ZandeButton>
                  <ZandeButton href={pedigreeLink(dog.zid)}>
                    pedigree
                  </ZandeButton>
                  <i className="text-muted small ml-2">(admin only)</i>
                </Form>
              </td>
            </ZandeRow>
            <ZandeRow>
              <th scope="row">fetched at:</th>
              <td>{formatDateTimeFull(dog.fetchedAt)}</td>
            </ZandeRow>
            <ZandeRow>
              <th scope="row">imported at:</th>
              <td>{formatDateTimeFull(dog.importedAt)}</td>
            </ZandeRow>
          </>
        )}
      </tbody>
    </Table>
  );
}

DogDetails.propTypes = {
  /** fulfilled by Relay fragment */
  dog: PropTypes.object,
};
