/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type SearchDogsResultItem_item$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type SearchDogsResult_results$ref: FragmentReference;
declare export opaque type SearchDogsResult_results$fragmentType: SearchDogsResult_results$ref;
export type SearchDogsResult_results = {|
  +nodes: $ReadOnlyArray<{|
    +dogId: ?string,
    +$fragmentRefs: SearchDogsResultItem_item$ref,
  |}>,
  +$refType: SearchDogsResult_results$ref,
|};
export type SearchDogsResult_results$data = SearchDogsResult_results;
export type SearchDogsResult_results$key = {
  +$data?: SearchDogsResult_results$data,
  +$fragmentRefs: SearchDogsResult_results$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SearchDogsResult_results",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SearchDogsResult",
      "kind": "LinkedField",
      "name": "nodes",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dogId",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SearchDogsResultItem_item"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SearchDogsResultsConnection",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'fe7e1cb77e570cb8cf73a564ddefaea4';

module.exports = node;
