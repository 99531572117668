/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type DogChildren_dog$ref = any;
export type Breedings_QueryVariables = {|
  id: string
|};
export type Breedings_QueryResponse = {|
  +dog: ?{|
    +$fragmentRefs: DogChildren_dog$ref
  |}
|};
export type Breedings_Query = {|
  variables: Breedings_QueryVariables,
  response: Breedings_QueryResponse,
|};
*/


/*
query Breedings_Query(
  $id: Id!
) {
  dog(id: $id) {
    ...DogChildren_dog
  }
}

fragment DogChildren_dog on Dog {
  id
  children {
    nodes {
      id
      birthdate
      sireId
      damId
      ...DogLink_dog
      sire {
        ...DogLink_dog
      }
      dam {
        ...DogLink_dog
      }
    }
  }
}

fragment DogLink_dog on Dog {
  id
  registeredName
  callName
  prefixes
  suffixes
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registeredName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "callName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "prefixes",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "suffixes",
  "storageKey": null
},
v7 = [
  (v2/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Breedings_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Dog",
        "kind": "LinkedField",
        "name": "dog",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DogChildren_dog"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Breedings_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Dog",
        "kind": "LinkedField",
        "name": "dog",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "DogsConnection",
            "kind": "LinkedField",
            "name": "children",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Dog",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "birthdate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sireId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "damId",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Dog",
                    "kind": "LinkedField",
                    "name": "sire",
                    "plural": false,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Dog",
                    "kind": "LinkedField",
                    "name": "dam",
                    "plural": false,
                    "selections": (v7/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1a23c5e293c1a380294d20318819dd6e",
    "id": null,
    "metadata": {},
    "name": "Breedings_Query",
    "operationKind": "query",
    "text": "query Breedings_Query(\n  $id: Id!\n) {\n  dog(id: $id) {\n    ...DogChildren_dog\n  }\n}\n\nfragment DogChildren_dog on Dog {\n  id\n  children {\n    nodes {\n      id\n      birthdate\n      sireId\n      damId\n      ...DogLink_dog\n      sire {\n        ...DogLink_dog\n      }\n      dam {\n        ...DogLink_dog\n      }\n    }\n  }\n}\n\nfragment DogLink_dog on Dog {\n  id\n  registeredName\n  callName\n  prefixes\n  suffixes\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'dcebd688bf881935674f8bee3aab486c';

module.exports = node;
