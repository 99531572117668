import React, { useCallback, useEffect, useState } from 'react';
import { fetchQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { useRelayEnvironment } from 'relay-hooks';
import { Link } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import { Helmet } from 'react-helmet';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarAlt,
  faUser,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';

import { prettifyRole } from '../../util/roles';
import { formatDate } from '../../util/dates';
import DebugDump from '../../components/DebugDump';

function ProfileIcon(props) {
  return <FontAwesomeIcon size="sm" fixedWidth className="mr-1" {...props} />;
}

const emptyProfile = {
  id: null,
  createdAt: null,
  updatedAt: null,
  firstName: '',
  lastName: '',
  email: '',
  gravatar: null,
  role: null,
  kennel: '',
};

export default function Profile() {
  const environment = useRelayEnvironment();
  const [fetched, setFetched] = useState(false);
  const [error, setError] = useState(null);
  const [changed, setChanged] = useState(false);
  const [profile, setProfile] = useState(emptyProfile);

  const handleFieldChange = useCallback((event) => {
    const { dataset, value } = event.currentTarget;
    const { field } = dataset;
    setProfile((prev) => ({
      ...prev,
      [field]: value,
    }));
    setChanged(true);
  }, []);

  const handleSubmit = useCallback((event) => {
    event.preventDefault();
    alert('TODO: update profile!');
  }, []);

  useEffect(() => {
    async function getProfile() {
      const query = graphql`
        query Profile_Query {
          profile: currentUser {
            id
            createdAt
            updatedAt
            firstName
            lastName
            email
            gravatar
            role
          }
        }
      `;

      const variables = {};

      try {
        const response = await fetchQuery(environment, query, variables);
        setFetched(true);
        setProfile(response.profile);
      } catch (error) {
        console.error('getting profile', { error });
        setProfile(emptyProfile);
        setFetched(true);
        setError(error);
      }
    }

    getProfile();
  }, [environment]);

  const {
    id,
    createdAt,
    firstName,
    lastName,
    email,
    gravatar,
    role,
    kennel,
  } = profile;

  return (
    <>
      <Helmet title="Your profile" />

      <Row className="border-bottom mb-3">
        <Col>
          <h2>Public profile</h2>
        </Col>
        <Col xs="auto">
          <Button
            as={Link}
            to={`/users/${id}`}
            variant="light"
            size="sm"
            className="border"
          >
            View public profile
          </Button>
        </Col>
      </Row>

      {!fetched ? (
        <>Loading...</>
      ) : (
        <>
          {error ? (
            <>Error: {error.message}</>
          ) : (
            <>
              <Row>
                <Col md={9}>
                  <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="name">
                      <Form.Label>Name</Form.Label>
                      <Form.Row>
                        <Col>
                          <Form.Control
                            value={firstName ?? ''}
                            onChange={handleFieldChange}
                            data-field="firstName"
                            placeholder="First"
                          />
                        </Col>
                        <Col>
                          <Form.Control
                            value={lastName ?? ''}
                            onChange={handleFieldChange}
                            data-field="lastName"
                            placeholder="Last"
                          />
                        </Col>
                      </Form.Row>
                      <Form.Text className="text-muted">
                        ???: If we link to kennels, we might want to expose the
                        user name on the kennel page... maybe?
                      </Form.Text>
                    </Form.Group>
                    <Form.Group controlId="email">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        value={email ?? ''}
                        onChange={handleFieldChange}
                        data-field="email"
                        placeholder="email@example.com"
                      />
                      <Form.Text className="text-muted">
                        ???: Allow the user to update/change their email? I
                        don't think we need to support more than one, though
                        (despite the nav on the left).
                      </Form.Text>
                    </Form.Group>
                    <Form.Group controlId="kennel">
                      <Form.Label>Kennel</Form.Label>
                      <Form.Control
                        value={kennel ?? ''}
                        onChange={handleFieldChange}
                        data-field="kennel"
                        placeholder="Your Kennel Name"
                      />
                      <Form.Text className="text-muted">
                        ???: Maybe we should allow users to list their kennel,
                        and/or we could verify them and make their updates (for
                        their own dogs) a bit easier?
                      </Form.Text>
                    </Form.Group>
                    <Form.Group controlId="extra">
                      <Form.Label>Extra</Form.Label>
                      <Form.Control placeholder="???" />
                    </Form.Group>
                    <Button
                      type="submit"
                      variant="success"
                      className="mt-2"
                      disabled={!changed}
                      title="TBD"
                    >
                      Update profile
                    </Button>
                  </Form>
                </Col>
                <Col md={3}>
                  <Image
                    rounded
                    src={`https://www.gravatar.com/avatar/${gravatar}?d=identicon&s=250`}
                    className="w-100"
                  />
                  <div className="text-muted m-3">
                    <div>
                      <ProfileIcon icon={faUser} />
                      {id}
                    </div>
                    <div>
                      <ProfileIcon icon={faUsers} />
                      {prettifyRole(role)}
                    </div>
                    <div>
                      <ProfileIcon icon={faCalendarAlt} />
                      <small>since</small> {formatDate(createdAt)}
                    </div>
                  </div>
                </Col>
              </Row>

              <h2 className="border-bottom mb-3 mt-5">
                Additional stuff... (?)
              </h2>

              <DebugDump level={1} object={profile} />
            </>
          )}
        </>
      )}
    </>
  );
}
