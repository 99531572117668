import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { fetchQuery } from 'react-relay';
import { useRelayEnvironment } from 'relay-hooks';
import graphql from 'babel-plugin-relay/macro';

import smartquotes from 'smartquotes';

import './TradingCard.scss';

const ancestorDepth = 2;

export default function TradingCard({ dogId, plainText }) {
  const environment = useRelayEnvironment();
  const [data, setData] = useState(null);

  useEffect(() => {
    async function fetchCardData() {
      if (!dogId) {
        return;
      }

      const query = graphql`
        query TradingCard_Query($id: Id!, $depth: Int!) {
          getDogAncestors(id: $id, depth: $depth) {
            nodes {
              dog {
                id
                registeredName
                prefixes
                suffixes
                sireId
                damId
                awards {
                  nodes {
                    granter
                    name
                    date
                    display
                  }
                }
              }
            }
          }
        }
      `;

      const variables = {
        id: dogId,
        depth: ancestorDepth + 1,
      };

      try {
        const data = await fetchQuery(environment, query, variables);
        setData(data);
      } catch (error) {
        console.log(`ERROR: ${JSON.stringify(error)}`);
      }
    }

    fetchCardData();
  }, [dogId, environment]);

  const [nameLine, setNameLine] = useState();
  const [years, setYears] = useState();
  const [bobLine, setBobLine] = useState();
  const [tree, setTree] = useState();

  useEffect(() => {
    if (!data) {
      return;
    }

    const dogs = mapifyDogs(data.getDogAncestors.nodes.map((item) => item.dog));
    const dog = dogs[dogId];

    setTree(
      buildAncestors(dogs, dogId, ancestorDepth).map((item) =>
        generateTreeDog(item, dogId),
      ),
    );

    const years = awardYears(dog);
    setYears(years);

    setNameLine(smartquotes(dog.registeredName));
    setBobLine(years.length === 0 ? null : `BEST OF BREED ${years.join(', ')}`);
  }, [dogId, data]);

  if (!dogId) {
    return;
  }

  if (!nameLine) {
    return <p className="text-muted">Loading...</p>;
  }

  if (plainText) {
    const lines = [nameLine];

    if (years?.length > 0) {
      lines.push(...years);
      lines.push(bobLine);
    }

    lines.push('PEDIGREE');
    lines.push(...tree?.map((i) => `${i.indent}${i.displayName}${i.awards}`));

    return <pre>{lines.join('\n')}</pre>;
  }

  return (
    <div className="tradingcard">
      <h2>{nameLine}</h2>
      {years?.map((y) => {
        const yearKey = `${dogId}-year-${y}`;
        return <h3 key={yearKey}>{y}</h3>;
      })}
      <h4>{bobLine}</h4>
      <h5>PEDIGREE</h5>
      {tree?.map((i) => (
        <div key={i.key} className={i.className}>
          {i.displayName}
          {i.awardsSpan}
        </div>
      ))}
    </div>
  );
}

TradingCard.propTypes = {
  dogId: PropTypes.string.isRequired,
  plainText: PropTypes.bool.isRequired,
};

// We create both formatted and plain-text versions of the trading card; this
// collects the data/strings in a semi-agnostic way so that the two rendering
// paths don't have to duplicate each other.
function generateTreeDog(item, rootId) {
  const { dog, depth } = item;

  const prefixes = dog.prefixes || [];
  const suffixes = dog.suffixes || [];

  const displayName = [
    ...prefixes,
    smartquotes(dog.registeredName),
    ...suffixes,
  ].join(' ');

  const years = awardYears(dog);
  let awards = '';
  let awardsSpan = null;
  if (depth > 0 && years.length > 0) {
    awards = ` (* ${years.join(', ')})`;
    awardsSpan = <span>{awards}</span>;
  }

  return {
    key: `${rootId}-${item.path.join('-')}`,
    displayName,
    awards,
    awardsSpan,
    className: `depth-${depth}`,
    indent: Array(depth).fill('\t').join(''),
  };
}

function awardYears(dog) {
  const awards = (dog.awards.nodes || []).filter(
    (award) => award.granter === 'EBC' && award.name === 'BOB',
  );
  return awards.map((a) => a.display);
}

function mapifyDogs(list) {
  // reduce the dogs to a map...
  const dogs = (list || []).reduce((acc, dog) => {
    acc[dog.id] = { ...dog };
    return acc;
  }, {});

  // add cross-references for any sires and dams...
  Object.values(dogs).forEach((dog) => {
    dog.sire = dogs[dog.sireId];
    dog.dam = dogs[dog.damId];
  });

  return dogs;
}

// Given a map of ids=>dogs, build the ancestry tree for a given "root" dog.
// The return value is an array of items in recursive [sire, root, dam] order,
// where each element includes the path from root and the depth.
function buildAncestors(dogs, dogId, depth, path) {
  const dog = dogId && dogs[dogId];
  path = path || [];

  if (!dog) {
    return [];
  }

  const thisDog = {
    path: path.slice(),
    depth: path.length,
    dog: dog,
  };

  if (depth === 0) {
    return [thisDog];
  }

  const sireList = buildAncestors(dogs, dog.sireId, depth - 1, [
    ...path,
    'sire',
  ]);
  const damList = buildAncestors(dogs, dog.damId, depth - 1, [...path, 'dam']);

  return [...sireList, thisDog, ...damList];
}
