/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Registries_QueryVariables = {||};
export type Registries_QueryResponse = {|
  +registries: ?{|
    +nodes: $ReadOnlyArray<{|
      +registry: string,
      +id: string,
      +description: ?string,
      +createdAt: string,
      +updatedAt: string,
      +registrations: {|
        +totalCount: number
      |},
    |}>
  |}
|};
export type Registries_Query = {|
  variables: Registries_QueryVariables,
  response: Registries_QueryResponse,
|};
*/


/*
query Registries_Query {
  registries(orderBy: REGISTRY_ASC) {
    nodes {
      registry
      id
      description
      createdAt
      updatedAt
      registrations {
        totalCount
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "orderBy",
        "value": "REGISTRY_ASC"
      }
    ],
    "concreteType": "RegistriesConnection",
    "kind": "LinkedField",
    "name": "registries",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Registry",
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "registry",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "RegistrationsConnection",
            "kind": "LinkedField",
            "name": "registrations",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalCount",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": "registries(orderBy:\"REGISTRY_ASC\")"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "Registries_Query",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "Registries_Query",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "18f743af200fe1b201e305a18a41105b",
    "id": null,
    "metadata": {},
    "name": "Registries_Query",
    "operationKind": "query",
    "text": "query Registries_Query {\n  registries(orderBy: REGISTRY_ASC) {\n    nodes {\n      registry\n      id\n      description\n      createdAt\n      updatedAt\n      registrations {\n        totalCount\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cb254f63c0d5aa5edba2c7375b2bf547';

module.exports = node;
