import React from 'react';
// import PropTypes from 'prop-types';
import { useQuery } from 'relay-hooks';
import graphql from 'babel-plugin-relay/macro';

import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

import DataTable from '../../components/DataTable';
import DebugDump from '../../components/DebugDump';

export default function Locations() {
  const { props: queryResult, error: queryError } = useQuery(
    graphql`
      query Locations_Query {
        locations(orderBy: LOCATION_ASC) {
          nodes {
            location
            id
            createdAt
            updatedAt
            dogs {
              totalCount
            }
          }
        }
        unlocated: dogs(condition: { locationId: null }) {
          totalCount
        }
      }
    `,
  );

  return (
    <>
      <Helmet title="Locations" />
      <Container className="mt-4">
        <h2 className="mb-3">Locations</h2>

        {queryError ? (
          <>
            <div>Error: {queryError.message}</div>
            <div>Source: {JSON.stringify(queryError.source)}</div>
          </>
        ) : !queryResult ? (
          <div>Loading...</div>
        ) : (
          <>
            <DataTable
              size="sm"
              columns={[
                { label: 'Name', fn: (d) => d.location },
                {
                  label: 'Dogs',
                  fn: (d) => d.dogs.totalCount,
                  className: 'text-right',
                },
              ]}
              standardExtraColumns
              data={queryResult?.locations?.nodes}
              extraRow={[
                <i>(unspecified)</i>,
                queryResult?.unlocated.totalCount,
              ]}
            />

            <DebugDump level={1} object={queryResult} />
          </>
        )}
      </Container>
    </>
  );
}
