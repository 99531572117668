import React from 'react';
// import PropTypes from 'prop-types';
import { useQuery } from 'relay-hooks';
import graphql from 'babel-plugin-relay/macro';
import { Link } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';

import { Helmet } from 'react-helmet';
import smartquotes from 'smartquotes';

import DayColumnsChart from '../../components/DayColumnsChart';

import DebugDump from '../../components/DebugDump';
import { luxonify, formatDateTimeFull, formatDateFull } from '../../util/dates';

const stats = [
  ['awardGranters', 'Award granters'],
  ['awardNames', 'Award names'],
  ['awards', 'Awards'],
  ['colors', 'Colors', '/admin/colors'],
  ['dogs', 'Dogs'],
  ['locations', 'Locations', '/admin/locations'],
  ['registrations', 'Registrations'],
  ['registries', 'Registries', '/admin/registries'],
  ['testAgencies', 'Test agencies'],
  ['testResults', 'Test results'],
  ['testTypes', 'Test types'],
  ['tests', 'Tests'],
  ['titles', 'Titles'],
  ['users', 'Users', '/admin/users'],
];

export default function Statistics() {
  const { props: queryResult, error: queryError } = useQuery(
    graphql`
      query Statistics_Query {
        mostRecentFetchedDog {
          id
          registeredName
          fetchedAt
        }
        leastRecentFetchedDog {
          id
          registeredName
          fetchedAt
        }
        oldestDog {
          id
          registeredName
          birthdate
        }
        youngestDog {
          id
          registeredName
          birthdate
        }

        awardGranters {
          totalCount
        }
        awardNames {
          totalCount
        }
        awards {
          totalCount
        }
        colors {
          totalCount
        }
        dogs {
          totalCount
        }
        locations {
          totalCount
        }
        registrations {
          totalCount
        }
        registries {
          totalCount
        }
        testAgencies {
          totalCount
        }
        testResults {
          totalCount
        }
        testTypes {
          totalCount
        }
        tests {
          totalCount
        }
        titles {
          totalCount
        }
        users {
          totalCount
        }

        fetchHistory {
          nodes {
            day
            count
          }
        }
      }
    `,
  );

  const {
    mostRecentFetchedDog,
    leastRecentFetchedDog,
    oldestDog,
    youngestDog,
  } = queryResult ?? {};

  // push into state?
  const fetchData = queryResult?.fetchHistory.nodes.map((n) => ({
    day: luxonify(n.day).toJSDate(),
    value: n.count,
  }));

  // console.log('*** fetch data', fetchData);
  const mostRecent = luxonify(mostRecentFetchedDog?.fetchedAt);
  const leastRecent = luxonify(leastRecentFetchedDog?.fetchedAt);
  const oldest = luxonify(oldestDog?.birthdate);
  const youngest = luxonify(youngestDog?.birthdate);

  return (
    <>
      <Helmet title="Statistics" />
      <Container className="mt-4">
        <h2 className="mb-3">Statistics</h2>

        {queryError ? (
          <>
            <div>Error: {queryError.message}</div>
            <div>Source: {JSON.stringify(queryError.source)}</div>
          </>
        ) : !queryResult ? (
          <div>Loading...</div>
        ) : (
          <>
            <Row>
              <Col sm={9}>
                <h4>Data acquisition</h4>
                <Table>
                  <tbody>
                    <tr>
                      <td>Most-recent fetch:</td>
                      <td>{formatDateTimeFull(mostRecent)}</td>
                      <td>({mostRecent.toRelative()})</td>
                      <td>
                        <Link to={`/dogs/${mostRecentFetchedDog.id}`}>
                          {smartquotes(mostRecentFetchedDog.registeredName)}
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <td>Least-recent fetch:</td>
                      <td>{formatDateTimeFull(leastRecent)}</td>
                      <td>({leastRecent.toRelative()})</td>
                      <td>
                        <Link to={`/dogs/${leastRecentFetchedDog.id}`}>
                          {smartquotes(leastRecentFetchedDog.registeredName)}
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <td>Oldest dog:</td>
                      <td>{formatDateFull(oldest)}</td>
                      <td>({oldest.toRelative()})</td>
                      <td>
                        <Link to={`/dogs/${oldestDog.id}`}>
                          {smartquotes(oldestDog.registeredName)}
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <td>Youngest dog:</td>
                      <td>{formatDateFull(youngest)}</td>
                      <td>({youngest.toRelative()})</td>
                      <td>
                        <Link to={`/dogs/${youngestDog.id}`}>
                          {smartquotes(youngestDog.registeredName)}
                        </Link>
                      </td>
                    </tr>
                  </tbody>
                </Table>

                <h4 className="pt-2">Fetch history</h4>
                <p>
                  The number of dogs retrieved from the Source of Truth&trade;
                  over the past month or so.
                </p>
                <DayColumnsChart width={800} height={200} data={fetchData} />
                <p className="small text-muted">
                  If the chart shows far more than one month along the x-axis,
                  especially with leading empty bars, check{' '}
                  <Link to="/admin/maintenance">the maintenance page</Link> to
                  see if there are old/duplicate dogs that need to be cleaned
                  up.
                </p>
              </Col>

              <Col sm={3}>
                <h4>Database</h4>
                <Table>
                  <thead>
                    <tr>
                      <th>Item</th>
                      <th>Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    {stats.map(([key, label, link]) => (
                      <tr key={key}>
                        <td>{link ? <Link to={link}>{label}</Link> : label}</td>
                        <td>{queryResult[key].totalCount}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
            <DebugDump level={1} object={queryResult} />
          </>
        )}
      </Container>
    </>
  );
}
