import React from 'react';
import PropTypes from 'prop-types';
import { useFragment } from 'relay-hooks';
import graphql from 'babel-plugin-relay/macro';

/**
 * The titles for a search result dog, either the prefix or suffix.  Different
 * from DogTitles, because this theoretically could include highlights.
 */
export default function SearchDogsResultTitles({ item: itemProp, side }) {
  const item = useFragment(
    graphql`
      fragment SearchDogsResultTitles_item on SearchDogsResult {
        prefixes
        suffixes
      }
    `,
    itemProp,
  );

  if (!item) {
    return null;
  }

  const titles = side === 'prefix' ? item.prefixes : item.suffixes;

  // console.log(`rendering titles for (${side}): ${JSON.stringify(titles)}`);

  if (!titles || titles.length === 0) {
    return null;
  }

  const padding = side === 'prefix' ? 'mr-1' : 'ml-1';
  const classNames = `titles ${side} ${padding}`;

  return (
    <span className={classNames} dangerouslySetInnerHTML={{ __html: titles }} />
  );
}

SearchDogsResultTitles.propTypes = {
  /** fulfilled by Relay fragment */
  item: PropTypes.object,
  /** which set of titles to */
  side: PropTypes.oneOf(['prefix', 'suffix']),
};
