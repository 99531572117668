import React from 'react';
// import PropTypes from 'prop-types';
import { useQuery } from 'relay-hooks';
import graphql from 'babel-plugin-relay/macro';
import { Redirect, useRouteMatch } from 'react-router-dom';

export default function RegistrationRedirector() {
  // The registry is optional, but appears first (simpl because that looks
  // somewhat more sane). We allow both '/reg/akc/hp44466204' and
  // '/reg/hp44466204'
  const match = useRouteMatch('/reg/:registry?/:registration');

  const { props: queryResult, error: queryError } = useQuery(
    graphql`
      query RegistrationRedirector_registry_Query(
        $registration: String!
        $registry: String
      ) {
        dogs: findDogsFromRegistration(
          registration: $registration
          registry: $registry
        ) {
          nodes {
            id
            registeredName
            registrations {
              registry
              registration
            }
          }
        }
      }
    `,
    {
      registration: match?.params.registration?.toUpperCase(),
      registry: match?.params.registry?.toUpperCase(),
      // registry: null,
    },
    {
      skip: !match?.params.registration,
    },
  );

  // console.log('***', {match, queryResult, queryError});

  // We redirect on error, or on success, but render nothing (return null)
  // otherwise, to wait for the results.
  if (queryError) {
    return <Redirect to="/" />;
  }

  if (queryResult) {
    const dogs = queryResult.dogs.nodes;
    if (dogs.length === 1) {
      return <Redirect to={`/dogs/${dogs[0].id}`} />;
    }

    // TODO: awesome would be to redirect to search with the registration, once
    // search includes registration!
    return <Redirect to="/" />;
  }

  return null;
}
