import React, { useCallback, useContext } from 'react';
// import PropTypes from 'prop-types';
import { useQuery } from 'relay-hooks';
import graphql from 'babel-plugin-relay/macro';
import { useParams, useRouteMatch } from 'react-router-dom';

import { Col, Container, Row } from 'react-bootstrap';

// import _ from 'lodash';
// import querystring from 'querystring';
import smartquotes from 'smartquotes';

import Search from '../../dogs/Search';
import PedigreeTree from '../../dogs/PedigreeTree';

import DogContext from './DogContext';

// import DebugDump from '../components/DebugDump';

const generationsToShow = 5;
const treeWidth = 1500;
const treeHeight = 1200;

export default function PedigreeExplorer() {
  const dog = useContext(DogContext);
  const { id } = useParams();
  const mateMatch = useRouteMatch('/dogs/:id/explore/:mateId');
  const mateId = mateMatch?.isExact && mateMatch?.params.mateId;

  const { props: queryResult, error: queryError } = useQuery(
    graphql`
      query PedigreeExplorer_Tree_Query($id: Id!, $mateId: Id!, $depth: Int!) {
        thisDog: dog(id: $id) {
          ...PedigreeTree_dog @arguments(depth: $depth)
        }
        otherDog: dog(id: $mateId) {
          ...PedigreeTree_otherDog @arguments(depth: $depth)
        }
      }
    `,
    {
      id,
      mateId,
      depth: generationsToShow - 1,
    },
    {
      skip: !mateId,
    },
  );

  const resultLink = useCallback(
    (item) => {
      return `/dogs/${id}/explore/${item.dogId}`;
    },
    [id],
  );

  if (!mateId) {
    const otherSex = dog.sex === 'M' ? 'F' : 'M';
    return (
      <Container>
        <p>
          To explore the possible pedigree for a future breeding, please search
          for a {otherSex ? (otherSex === 'M' ? 'sire' : 'dam') : 'mate'} to use
          with {smartquotes(dog?.registeredName)}.
        </p>
        <Row>
          <Col md="8" lg="6" className="mb-2">
            <Search resultLink={resultLink} sex={otherSex} />
          </Col>
        </Row>
      </Container>
    );
  }

  const { thisDog, otherDog } = queryResult ?? {};

  return (
    <Container fluid={false}>
      {queryError ? (
        <>
          <div>Error: {queryError.message}</div>
          <div>Source: {JSON.stringify(queryError.source)}</div>
        </>
      ) : !queryResult ? (
        <div>Loading...</div>
      ) : (
        <PedigreeTree
          width={treeWidth}
          height={treeHeight}
          dog={thisDog}
          otherDog={otherDog}
          generations={generationsToShow}
        />
      )}
    </Container>
  );
}
