/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type DogTitles_dog$ref = any;
export type Sex = "F" | "M" | "%future added value";
export type Dogs_QueryVariables = {|
  id: string
|};
export type Dogs_QueryResponse = {|
  +dog: ?{|
    +id: string,
    +registeredName: string,
    +sex: ?Sex,
    +prefixes: ?$ReadOnlyArray<?string>,
    +suffixes: ?$ReadOnlyArray<?string>,
    +$fragmentRefs: DogTitles_dog$ref,
  |}
|};
export type Dogs_Query = {|
  variables: Dogs_QueryVariables,
  response: Dogs_QueryResponse,
|};
*/


/*
query Dogs_Query(
  $id: Id!
) {
  dog(id: $id) {
    id
    registeredName
    sex
    prefixes
    suffixes
    ...DogTitles_dog
  }
}

fragment DogTitles_dog on Dog {
  prefixes
  suffixes
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registeredName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sex",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "prefixes",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "suffixes",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Dogs_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Dog",
        "kind": "LinkedField",
        "name": "dog",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DogTitles_dog"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Dogs_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Dog",
        "kind": "LinkedField",
        "name": "dog",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "00b4338a82b8657d8c09f578a11838cb",
    "id": null,
    "metadata": {},
    "name": "Dogs_Query",
    "operationKind": "query",
    "text": "query Dogs_Query(\n  $id: Id!\n) {\n  dog(id: $id) {\n    id\n    registeredName\n    sex\n    prefixes\n    suffixes\n    ...DogTitles_dog\n  }\n}\n\nfragment DogTitles_dog on Dog {\n  prefixes\n  suffixes\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1e11115ca24edff800f0c11229671c9d';

module.exports = node;
