import React from 'react';
// import PropTypes from 'prop-types';
import { useQuery } from 'relay-hooks';
import graphql from 'babel-plugin-relay/macro';

import { Link, useParams } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';

import { Helmet } from 'react-helmet';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarAlt,
  faEnvelope,
  faLink,
  faMapMarkerAlt,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';

import { prettifyRole } from '../util/roles';
import { formatDate } from '../util/dates';

import DebugDump from '../components/DebugDump';

function ProfileIcon(props) {
  return <FontAwesomeIcon size="sm" fixedWidth className="mr-1" {...props} />;
}

export default function User(props) {
  const { id } = useParams();

  const { props: queryResult, error: queryError } = useQuery(
    graphql`
      query User_Query($id: Id!) {
        self: currentUser {
          id
        }
        user: user(id: $id) {
          id
          createdAt
          updatedAt
          firstName
          lastName
          fullName
          email
          gravatar
          role
        }
      }
    `,
    { id },
  );

  const { self, user } = queryResult ?? {};

  return (
    <Container className="mt-4">
      {queryError ? (
        <>
          <div>Error: {queryError.message}</div>
          <div>Source: {JSON.stringify(queryError.source)}</div>
        </>
      ) : !queryResult ? (
        <div>Loading...</div>
      ) : (
        <>
          <Helmet title={user.fullName} />
          <Row>
            <Col md={3}>
              <Image
                rounded
                src={`https://www.gravatar.com/avatar/${user.gravatar}?d=identicon&s=250`}
              />

              <div className="my-3">
                <h3>{user.fullName}</h3>
              </div>

              {user.id === self.id && (
                <Button
                  as={Link}
                  to="/settings/profile"
                  variant="light"
                  size="sm"
                  className="w-100 border my-3"
                >
                  Edit profile
                </Button>
              )}

              <div className="text-muted">
                <div>
                  <ProfileIcon icon={faUsers} />
                  {prettifyRole(user.role)}
                </div>

                {false && user.email && (
                  <div>
                    <ProfileIcon icon={faEnvelope} />
                    {user.email}
                  </div>
                )}

                {false && (
                  <div>
                    <ProfileIcon icon={faMapMarkerAlt} />
                    location?
                  </div>
                )}

                {false && (
                  <div>
                    <ProfileIcon icon={faLink} />
                    <Button
                      variant="link"
                      className="p-0 border-0 align-baseline"
                    >
                      <i>link?</i>
                    </Button>
                  </div>
                )}

                <div>
                  <ProfileIcon icon={faCalendarAlt} />
                  <small>since</small> {formatDate(user.createdAt)}
                </div>
              </div>
            </Col>
            <Col md={9}>
              <div className="p-2 bg-light border">
                <h3>TODO:</h3>
                <ul>
                  <li>???</li>
                </ul>
                <p>
                  What should we show on the "public" user profile page? Some
                  kind of stats for "how many dogs has this person helped
                  update" would be cool.
                </p>
              </div>
            </Col>
          </Row>
        </>
      )}
      <DebugDump level={1} object={queryResult} />
    </Container>
  );
}
