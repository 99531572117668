/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type User_QueryVariables = {|
  id: string
|};
export type User_QueryResponse = {|
  +self: ?{|
    +id: string
  |},
  +user: ?{|
    +id: string,
    +createdAt: string,
    +updatedAt: string,
    +firstName: string,
    +lastName: string,
    +fullName: ?string,
    +email: ?string,
    +gravatar: ?string,
    +role: ?string,
  |},
|};
export type User_Query = {|
  variables: User_QueryVariables,
  response: User_QueryResponse,
|};
*/


/*
query User_Query(
  $id: Id!
) {
  self: currentUser {
    id
  }
  user(id: $id) {
    id
    createdAt
    updatedAt
    firstName
    lastName
    fullName
    email
    gravatar
    role
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": "self",
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "currentUser",
    "plural": false,
    "selections": [
      (v1/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "user",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "updatedAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "fullName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "gravatar",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "role",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "User_Query",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "User_Query",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "5a388e43954ea1a8dd8b8795300f2ee3",
    "id": null,
    "metadata": {},
    "name": "User_Query",
    "operationKind": "query",
    "text": "query User_Query(\n  $id: Id!\n) {\n  self: currentUser {\n    id\n  }\n  user(id: $id) {\n    id\n    createdAt\n    updatedAt\n    firstName\n    lastName\n    fullName\n    email\n    gravatar\n    role\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '396aab204c5951ebc94fbecfa9060fef';

module.exports = node;
