/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Statistics_QueryVariables = {||};
export type Statistics_QueryResponse = {|
  +mostRecentFetchedDog: ?{|
    +id: string,
    +registeredName: string,
    +fetchedAt: ?string,
  |},
  +leastRecentFetchedDog: ?{|
    +id: string,
    +registeredName: string,
    +fetchedAt: ?string,
  |},
  +oldestDog: ?{|
    +id: string,
    +registeredName: string,
    +birthdate: ?string,
  |},
  +youngestDog: ?{|
    +id: string,
    +registeredName: string,
    +birthdate: ?string,
  |},
  +awardGranters: ?{|
    +totalCount: number
  |},
  +awardNames: ?{|
    +totalCount: number
  |},
  +awards: ?{|
    +totalCount: number
  |},
  +colors: ?{|
    +totalCount: number
  |},
  +dogs: ?{|
    +totalCount: number
  |},
  +locations: ?{|
    +totalCount: number
  |},
  +registrations: ?{|
    +totalCount: number
  |},
  +registries: ?{|
    +totalCount: number
  |},
  +testAgencies: ?{|
    +totalCount: number
  |},
  +testResults: ?{|
    +totalCount: number
  |},
  +testTypes: ?{|
    +totalCount: number
  |},
  +tests: ?{|
    +totalCount: number
  |},
  +titles: ?{|
    +totalCount: number
  |},
  +users: ?{|
    +totalCount: number
  |},
  +fetchHistory: {|
    +nodes: $ReadOnlyArray<{|
      +day: ?string,
      +count: ?number,
    |}>
  |},
|};
export type Statistics_Query = {|
  variables: Statistics_QueryVariables,
  response: Statistics_QueryResponse,
|};
*/


/*
query Statistics_Query {
  mostRecentFetchedDog {
    id
    registeredName
    fetchedAt
  }
  leastRecentFetchedDog {
    id
    registeredName
    fetchedAt
  }
  oldestDog {
    id
    registeredName
    birthdate
  }
  youngestDog {
    id
    registeredName
    birthdate
  }
  awardGranters {
    totalCount
  }
  awardNames {
    totalCount
  }
  awards {
    totalCount
  }
  colors {
    totalCount
  }
  dogs {
    totalCount
  }
  locations {
    totalCount
  }
  registrations {
    totalCount
  }
  registries {
    totalCount
  }
  testAgencies {
    totalCount
  }
  testResults {
    totalCount
  }
  testTypes {
    totalCount
  }
  tests {
    totalCount
  }
  titles {
    totalCount
  }
  users {
    totalCount
  }
  fetchHistory {
    nodes {
      day
      count
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registeredName",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "fetchedAt",
    "storageKey": null
  }
],
v3 = [
  (v0/*: any*/),
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "birthdate",
    "storageKey": null
  }
],
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
],
v5 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Dog",
    "kind": "LinkedField",
    "name": "mostRecentFetchedDog",
    "plural": false,
    "selections": (v2/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Dog",
    "kind": "LinkedField",
    "name": "leastRecentFetchedDog",
    "plural": false,
    "selections": (v2/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Dog",
    "kind": "LinkedField",
    "name": "oldestDog",
    "plural": false,
    "selections": (v3/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Dog",
    "kind": "LinkedField",
    "name": "youngestDog",
    "plural": false,
    "selections": (v3/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "AwardGrantersConnection",
    "kind": "LinkedField",
    "name": "awardGranters",
    "plural": false,
    "selections": (v4/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "AwardNamesConnection",
    "kind": "LinkedField",
    "name": "awardNames",
    "plural": false,
    "selections": (v4/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "AwardsConnection",
    "kind": "LinkedField",
    "name": "awards",
    "plural": false,
    "selections": (v4/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "ColorsConnection",
    "kind": "LinkedField",
    "name": "colors",
    "plural": false,
    "selections": (v4/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "DogsConnection",
    "kind": "LinkedField",
    "name": "dogs",
    "plural": false,
    "selections": (v4/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "LocationsConnection",
    "kind": "LinkedField",
    "name": "locations",
    "plural": false,
    "selections": (v4/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "RegistrationsConnection",
    "kind": "LinkedField",
    "name": "registrations",
    "plural": false,
    "selections": (v4/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "RegistriesConnection",
    "kind": "LinkedField",
    "name": "registries",
    "plural": false,
    "selections": (v4/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "TestAgenciesConnection",
    "kind": "LinkedField",
    "name": "testAgencies",
    "plural": false,
    "selections": (v4/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "TestResultsConnection",
    "kind": "LinkedField",
    "name": "testResults",
    "plural": false,
    "selections": (v4/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "TestTypesConnection",
    "kind": "LinkedField",
    "name": "testTypes",
    "plural": false,
    "selections": (v4/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "TestsConnection",
    "kind": "LinkedField",
    "name": "tests",
    "plural": false,
    "selections": (v4/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "TitlesConnection",
    "kind": "LinkedField",
    "name": "titles",
    "plural": false,
    "selections": (v4/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "UsersConnection",
    "kind": "LinkedField",
    "name": "users",
    "plural": false,
    "selections": (v4/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "FetchCountsConnection",
    "kind": "LinkedField",
    "name": "fetchHistory",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "FetchCount",
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "day",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "count",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "Statistics_Query",
    "selections": (v5/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "Statistics_Query",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "c24b0fc651f39e97c5b778a966df0acf",
    "id": null,
    "metadata": {},
    "name": "Statistics_Query",
    "operationKind": "query",
    "text": "query Statistics_Query {\n  mostRecentFetchedDog {\n    id\n    registeredName\n    fetchedAt\n  }\n  leastRecentFetchedDog {\n    id\n    registeredName\n    fetchedAt\n  }\n  oldestDog {\n    id\n    registeredName\n    birthdate\n  }\n  youngestDog {\n    id\n    registeredName\n    birthdate\n  }\n  awardGranters {\n    totalCount\n  }\n  awardNames {\n    totalCount\n  }\n  awards {\n    totalCount\n  }\n  colors {\n    totalCount\n  }\n  dogs {\n    totalCount\n  }\n  locations {\n    totalCount\n  }\n  registrations {\n    totalCount\n  }\n  registries {\n    totalCount\n  }\n  testAgencies {\n    totalCount\n  }\n  testResults {\n    totalCount\n  }\n  testTypes {\n    totalCount\n  }\n  tests {\n    totalCount\n  }\n  titles {\n    totalCount\n  }\n  users {\n    totalCount\n  }\n  fetchHistory {\n    nodes {\n      day\n      count\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'eaa29ead64d12e815f5a61e4fa608940';

module.exports = node;
