/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type SearchStats_results$ref: FragmentReference;
declare export opaque type SearchStats_results$fragmentType: SearchStats_results$ref;
export type SearchStats_results = {|
  +totalCount: number,
  +$refType: SearchStats_results$ref,
|};
export type SearchStats_results$data = SearchStats_results;
export type SearchStats_results$key = {
  +$data?: SearchStats_results$data,
  +$fragmentRefs: SearchStats_results$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SearchStats_results",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    }
  ],
  "type": "SearchDogsResultsConnection",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'c40f08e1bdef56a7eb21e23a277aba90';

module.exports = node;
