import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Button, Card, Col, Container, ListGroup, Row } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faNpm, faDocker } from '@fortawesome/free-brands-svg-icons';

import AppJumbo from '../components/AppJumbo';
import Changelog from '../components/Changelog';

function CardIconButton(props) {
  return (
    <Button variant="outline-dark" href={props.href}>
      <FontAwesomeIcon icon={props.icon} />
    </Button>
  );
}

// changelog formatting...
const FeatureRelease = styled.div`
  font-size: 120%;
  font-weight: bold;
`;

const PatchRelease = styled.div`
  /* font-weight: bold; */
`;

const Section = styled.div`
  /* font-weight: bold; */
  margin-left: 1em;
  font-style: italic;
`;

const maryEllenLink = '/users/2vWz6';
const jaredLink = '/users/W6Kpv';

export default function About() {
  return (
    <div>
      <AppJumbo />

      <Container className="mt-4 mb-4">
        <Row>
          <Col lg={9}>
            <p>In the spring of 2017, we asked ourselves the question:</p>

            <blockquote className="alert alert-secondary blockquote mx-4">
              <h3>Why don’t pedigree sites look more like family trees?</h3>
              <footer className="blockquote-footer text-right">
                Ourselves, Spring 2017
              </footer>
            </blockquote>

            <p>
              ... and it turned out the answer was: “There’s no <em>good</em>{' '}
              reason, it’s just that pedigree websites barely even qualify as
              ‘Web 1.0’.”
            </p>

            <p>
              The other thing we noticed is that maintaining a database of dogs
              year after year usually falls on the shoulders of one person, or a
              very select few. That’s a large workload when you consider that
              the database often includes dogs from the entire planet. (Even for
              a breed as “less-popular” as Basenjis, we’re talking on the order
              of XX new puppies entered every year.) As of 2017, we’re well into
              the age of open-source and crowd-sourcing; it’s entirely
              reasonable to give owners the ability to submit updates directly
              to the system, or maybe even grant them more direct control, a la
              Discourse’s “good behavior grants additional power” model.
            </p>

            <h4>Created by</h4>

            <Row className="mt-3 mb-4">
              <Col sm={{ size: 5, offset: 1 }}>
                <Card bg="secondary">
                  <Link to={maryEllenLink}>
                    <Card.Img src="https://www.gravatar.com/avatar/984f580e846028f85e02eceec33b3e50?d=identicon&s=350" />
                  </Link>
                  <Card.Body>
                    <Card.Title>
                      <Link to={maryEllenLink} className="text-dark">
                        Mary Ellen Chaffin
                      </Link>
                    </Card.Title>
                    <Card.Subtitle>Chief Instigator</Card.Subtitle>
                    <Card.Text className="mt-3">
                      Mary Ellen is owned by three Basenjis: Echo, Zoe, and
                      Juno.
                    </Card.Text>
                    <Card.Text className="mt-3">
                      <CardIconButton
                        icon={faGithub}
                        href="https://github.com/mechaffin"
                      />
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={{ size: 5, offset: 0 }}>
                <Card bg="secondary">
                  <Link to={jaredLink}>
                    <Card.Img src="https://www.gravatar.com/avatar/90d241c992715d748b52cf3fafbfe31b?d=identicon&s=350" />
                  </Link>
                  <Card.Body>
                    <Card.Title>
                      <Link to={jaredLink} className="text-dark">
                        Jared Reisinger
                      </Link>
                    </Card.Title>
                    <Card.Subtitle>Visualizer, First Lackey</Card.Subtitle>
                    <Card.Text className="mt-3">
                      Jared is kept to task by his three Basenjis (Disa, Spyros,
                      and Gable) and one wife (Barbara), all of whom he finds
                      ridiculously adorable.
                    </Card.Text>
                    <Card.Text className="mt-3">
                      <CardIconButton
                        icon={faGithub}
                        href="https://github.com/JaredReisinger"
                      />{' '}
                      <CardIconButton
                        icon={faNpm}
                        href="https://www.npmjs.com/~jaredreisinger"
                      />{' '}
                      <CardIconButton
                        icon={faDocker}
                        href="https://hub.docker.com/u/jaredreisinger/"
                      />
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <h4>Roadmap</h4>

            <p>Not necessarily in this order:</p>

            <ListGroup variant="flush" className="mb-4">
              <ListGroup.Item>kennel information</ListGroup.Item>
              <ListGroup.Item>
                continue fleshing out dog information
              </ListGroup.Item>
              <ListGroup.Item>
                user profile editing (password change, etc.)
              </ListGroup.Item>
              <ListGroup.Item>administrator dog updates</ListGroup.Item>
              <ListGroup.Item>user-submitted dog updates</ListGroup.Item>
            </ListGroup>
          </Col>
          <Col lg={3}>
            <Card bg="light" className="text-muted">
              <Card.Header>Changelog</Card.Header>
              <Card.Body style={{ fontSize: '84%' }}>
                <Changelog
                  featureRelease={FeatureRelease}
                  patchRelease={PatchRelease}
                  section={Section}
                  lineLimit={50}
                />
                <div className="mt-2">
                  <i>(...snippped...)</i>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
