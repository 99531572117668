/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Locations_QueryVariables = {||};
export type Locations_QueryResponse = {|
  +locations: ?{|
    +nodes: $ReadOnlyArray<{|
      +location: string,
      +id: string,
      +createdAt: string,
      +updatedAt: string,
      +dogs: {|
        +totalCount: number
      |},
    |}>
  |},
  +unlocated: ?{|
    +totalCount: number
  |},
|};
export type Locations_Query = {|
  variables: Locations_QueryVariables,
  response: Locations_QueryResponse,
|};
*/


/*
query Locations_Query {
  locations(orderBy: LOCATION_ASC) {
    nodes {
      location
      id
      createdAt
      updatedAt
      dogs {
        totalCount
      }
    }
  }
  unlocated: dogs(condition: {locationId: null}) {
    totalCount
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "orderBy",
        "value": "LOCATION_ASC"
      }
    ],
    "concreteType": "LocationsConnection",
    "kind": "LinkedField",
    "name": "locations",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Location",
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "location",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DogsConnection",
            "kind": "LinkedField",
            "name": "dogs",
            "plural": false,
            "selections": (v0/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": "locations(orderBy:\"LOCATION_ASC\")"
  },
  {
    "alias": "unlocated",
    "args": [
      {
        "kind": "Literal",
        "name": "condition",
        "value": {
          "locationId": null
        }
      }
    ],
    "concreteType": "DogsConnection",
    "kind": "LinkedField",
    "name": "dogs",
    "plural": false,
    "selections": (v0/*: any*/),
    "storageKey": "dogs(condition:{\"locationId\":null})"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "Locations_Query",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "Locations_Query",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e4f206b9ac0c08000c205ca8de9e9445",
    "id": null,
    "metadata": {},
    "name": "Locations_Query",
    "operationKind": "query",
    "text": "query Locations_Query {\n  locations(orderBy: LOCATION_ASC) {\n    nodes {\n      location\n      id\n      createdAt\n      updatedAt\n      dogs {\n        totalCount\n      }\n    }\n  }\n  unlocated: dogs(condition: {locationId: null}) {\n    totalCount\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cec92d7203a17dee0673ab7170fd65c3';

module.exports = node;
