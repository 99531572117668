import React, { useContext } from 'react';
import { Col, Container, Jumbotron, Row } from 'react-bootstrap';
import styled from 'styled-components';

import { AppContext } from '../util/AppContext';

// TODO: get colors from theme provider!
const JumboLogo = styled.svg`
  fill: hsl(195, 50%, 52%);
  height: 80px;
`;

/** A "jumbotron" header to show the app logo, name, and version. */
export default function AppJumbo() {
  const { appInfo } = useContext(AppContext);
  const { name, version, description, Logo } = appInfo;

  return (
    <Jumbotron className="bg-light">
      <Container>
        <Row>
          <Col>
            <h1 className="display-3">
              <JumboLogo as={Logo} />
              <br />
              {name + ' '}
              <span style={{ fontSize: '50%', opacity: '50%' }}>
                v{version}
              </span>
            </h1>
            <p className="lead">{description}</p>
          </Col>
        </Row>
      </Container>
    </Jumbotron>
  );
}
