/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RequestPasswordResetInput = {|
  clientMutationId?: ?string,
  email: string,
|};
export type Login_requestPasswordReset_MutationVariables = {|
  input: RequestPasswordResetInput
|};
export type Login_requestPasswordReset_MutationResponse = {|
  +requestPasswordReset: ?{|
    +clientMutationId: ?string
  |}
|};
export type Login_requestPasswordReset_Mutation = {|
  variables: Login_requestPasswordReset_MutationVariables,
  response: Login_requestPasswordReset_MutationResponse,
|};
*/


/*
mutation Login_requestPasswordReset_Mutation(
  $input: RequestPasswordResetInput!
) {
  requestPasswordReset(input: $input) {
    clientMutationId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RequestPasswordResetPayload",
    "kind": "LinkedField",
    "name": "requestPasswordReset",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Login_requestPasswordReset_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Login_requestPasswordReset_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4c2d36854249dc795b848f6a0cf12e32",
    "id": null,
    "metadata": {},
    "name": "Login_requestPasswordReset_Mutation",
    "operationKind": "mutation",
    "text": "mutation Login_requestPasswordReset_Mutation(\n  $input: RequestPasswordResetInput!\n) {\n  requestPasswordReset(input: $input) {\n    clientMutationId\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e289d9fd94f34477def8139e3a4642bc';

module.exports = node;
