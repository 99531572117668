import React from 'react';
// import PropTypes from 'prop-types';
import { useQuery } from 'relay-hooks';
import graphql from 'babel-plugin-relay/macro';

import { Container } from 'react-bootstrap/';
import { Helmet } from 'react-helmet';

import DataTable from '../../components/DataTable';
import DebugDump from '../../components/DebugDump';

export default function Colors() {
  const { props: queryResult, error: queryError } = useQuery(
    graphql`
      query Colors_Query {
        colors(orderBy: COLOR_ASC) {
          nodes {
            color
            id
            createdAt
            updatedAt
            dogs {
              totalCount
            }
          }
        }
        uncolored: dogs(condition: { colorId: null }) {
          totalCount
        }
      }
    `,
  );

  return (
    <>
      <Helmet title="Colors" />
      <Container className="mt-4">
        <h2 className="mb-3">Colors</h2>

        {queryError ? (
          <>
            <div>Error: {queryError.message}</div>
            <div>Source: {JSON.stringify(queryError.source)}</div>
          </>
        ) : !queryResult ? (
          <div>Loading...</div>
        ) : (
          <>
            <DataTable
              size="sm"
              columns={[
                { label: 'Name', fn: (d) => d.color },
                {
                  label: 'Dogs',
                  fn: (d) => d.dogs.totalCount,
                  className: 'text-right',
                },
              ]}
              standardExtraColumns
              data={queryResult?.colors?.nodes}
              extraRow={[
                <i>(unspecified, probably Red &amp; White)</i>,
                queryResult?.uncolored.totalCount,
              ]}
            />

            <DebugDump level={1} object={queryResult} />
          </>
        )}
      </Container>
      {/* <DebugDump level={1} object={this.queryResults} /> */}
    </>
  );
}
