import React from 'react';
// import PropTypes from 'prop-types';
import { useQuery } from 'relay-hooks';
import graphql from 'babel-plugin-relay/macro';
import { useParams } from 'react-router-dom';

import Container from 'react-bootstrap/Container';

import DogDetails from '../../dogs/DogDetails';

// import DebugDump from '../components/DebugDump';

export default function Details() {
  const { id } = useParams();

  const { props: queryResult, error: queryError } = useQuery(
    graphql`
      query Details_Query($id: Id!) {
        dog(id: $id) {
          ...DogDetails_dog
        }
      }
    `,
    {
      id,
    },
  );

  const { dog } = queryResult ?? {};

  return (
    <Container>
      {queryError ? (
        <>
          <div>Error: {queryError.message}</div>
          <div>Source: {JSON.stringify(queryError.source)}</div>
        </>
      ) : !queryResult ? (
        <div>Loading...</div>
      ) : (
        <>
          <DogDetails dog={dog} />
        </>
      )}
    </Container>
  );
}
