import React, { useCallback, useState } from 'react';
// import PropTypes from 'prop-types';

import { Container, Row, Col } from 'react-bootstrap';

import AppJumbo from '../components/AppJumbo';
import SearchComponent from '../dogs/Search';

// REVIEW (jared): the following note seems old!
//
// In order to get queries to auto-load on navigation (routing), we seed them in
// as an `initialQuery` property on the search box.

export default function Search() {
  const [showAppJumbo, setShowAppJumbo] = useState(true);

  const handleQueryChange = useCallback((query) => {
    setShowAppJumbo(!query || query === '');
  }, []);

  return (
    <>
      <Container>
        <Row>
          <Col md="8" lg="6">
            <SearchComponent onChange={handleQueryChange} />
          </Col>
        </Row>
      </Container>

      {showAppJumbo && <AppJumbo />}
    </>
  );
}
