import React, { useCallback, useEffect, useRef, useState } from 'react';
import { fetchQuery } from 'react-relay';
import { Environment, RecordSource, Store } from 'relay-runtime';
import {
  RelayNetworkLayer,
  authMiddleware,
  loggerMiddleware,
} from 'react-relay-network-modern/lib';
import { RelayEnvironmentProvider } from 'relay-hooks';
import graphql from 'babel-plugin-relay/macro';

import {
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom';

import { Helmet } from 'react-helmet';

import { AppContext } from './util/AppContext';

import MainNav from './components/MainNav';
import Login from './pages/Login';
import Search from './pages/Search';
import Dogs from './pages/Dogs';
import Kennels from './pages/Kennels';
import About from './pages/About';
import User from './pages/User';
import Settings from './pages/Settings';
import Admin from './pages/Admin';

import RegistrationRedirector from './util/RegistrationRedirector';

// import appLogo from './logo.png';
import { ReactComponent as AppLogo } from './logo.svg';
import './App.scss';

const authTokenStorageKey = 'pedigree-auth';

function createRelayEnvironment(authToken) {
  const store = new Store(new RecordSource());
  const network = new RelayNetworkLayer([
    authMiddleware({
      token: authToken,
    }),
    loggerMiddleware(),
  ]);

  return new Environment({ network, store });
}

function nameFromLocation(location) {
  const parts = location.pathname.split('/');
  const area = parts[1];

  if (!area || area === '') {
    return null;
  }

  return `${area.substring(0, 1).toUpperCase()}${area.substring(1)}`;
}

export default function App({ appName, appVersion, appDesc }) {
  const [authToken, setAuthToken] = useState();
  const [environment, setEnvironment] = useState(createRelayEnvironment());
  const [currentRole, setCurrentRole] = useState('pedigree_anonymous');
  const [currentUser, setCurrentUser] = useState(null);
  const [debug /* setDebug */] = useState(0);

  // These bounce a lot, since we mess with the querystring...
  const history = useHistory();
  const location = useLocation();
  const historyRef = useRef();
  const locationRef = useRef();

  historyRef.current = history;
  locationRef.current = location;

  const [appContext, setAppContext] = useState({
    appInfo: {
      name: appName,
      version: appVersion,
      description: appDesc,
      Logo: AppLogo,
    },
    appName,
    appVersion,
    appDesc,
    AppLogo,
    authToken, // should this be "signedIn" and/or a user name instead?
    currentRole,
    isAdmin: currentRole === 'pedigree_admin',
    currentUser,
    debug,
  });

  useEffect(() => {
    setAppContext({
      appInfo: {
        name: appName,
        version: appVersion,
        description: appDesc,
        Logo: AppLogo,
      },
      appName,
      appVersion,
      appDesc,
      AppLogo,
      authToken,
      currentRole,
      isAdmin: currentRole === 'pedigree_admin',
      currentUser,
      debug,
    });
  }, [
    appName,
    appVersion,
    appDesc,
    authToken,
    currentRole,
    currentUser,
    debug,
  ]);

  const forceLogin = useCallback(() => {
    const loginPath = '/login';

    // replace (not push!) the current location with the login page, but stash
    // the current location as the 'returnTo' state so that we know where to go
    // if/when a login is successful
    if (!locationRef.current.pathname.startsWith(loginPath)) {
      historyRef.current.replace({
        pathname: loginPath,
        state: {
          returnTo: locationRef.current,
        },
      });
    }
  }, []);

  const forceLogout = useCallback(() => {
    // By setting the auth to null, we'll eventually trigger a forceLogin such
    // that the user will return to the original page (under the new
    // credentials) after they've signed back in.
    setAuthToken(null);
  }, []);

  const updateCurrentUser = useCallback((authToken, environment) => {
    if (!authToken) {
      setCurrentRole('pedigree_anonymous');
      setCurrentUser(null);
      return;
    }

    async function updateCurrentUserAsync() {
      const query = graphql`
        query App_currentUser_Query {
          currentRole
          currentUser {
            id
            firstName
            fullName
            gravatar
          }
        }
      `;

      const variables = {};

      try {
        const response = await fetchQuery(environment, query, variables);

        const { currentRole, currentUser } = response;

        setCurrentRole(currentRole);
        setCurrentUser(currentUser);
      } catch (error) {
        if (error.res && error.res.status === 401) {
          setAuthToken(null);
          setCurrentRole('pedigree_anonymous');
          setCurrentUser(null);
          return;
        }

        console.error('getting currentUser', { error });
      }
    }

    updateCurrentUserAsync();
  }, []);

  useEffect(() => {
    if (window.localStorage) {
      let storedToken = window.localStorage.getItem(authTokenStorageKey);
      // sometimes we end up with the *string* 'null'...
      if (storedToken === 'null') {
        storedToken = null;
      }

      setAuthToken(storedToken);
    }
  }, []);

  useEffect(() => {
    // persist to local storage...
    if (window.localStorage) {
      if (authToken) {
        window.localStorage.setItem(authTokenStorageKey, authToken);
        // console.log(`auth token to storage: ${authToken}`);
      } else {
        window.localStorage.removeItem(authTokenStorageKey);
      }
    }

    const newEnvironment = createRelayEnvironment(authToken);
    setEnvironment(newEnvironment);
    updateCurrentUser(authToken, newEnvironment);

    if (!authToken) {
      forceLogin();
    }
  }, [authToken, forceLogin, updateCurrentUser]);

  const name = nameFromLocation(location);
  const prefix = name ? `${name} - ` : '';

  const title = `${prefix}${appName}`;

  return (
    <div className="App">
      <RelayEnvironmentProvider environment={environment}>
        <AppContext.Provider value={appContext}>
          <Helmet defaultTitle={title} titleTemplate={`%s - ${title}`} />

          <MainNav
            currentRole={currentRole}
            currentUser={currentUser}
            onSignOut={forceLogout}
          />

          <Switch>
            <Route path="/" exact component={Search} />

            <Route path="/login">
              <Login onAuthChange={setAuthToken} />
            </Route>

            <Route path="/dogs/:id" component={Dogs} />
            <Route path="/kennels" component={Kennels} />
            <Route path="/about" component={About} />
            <Route path="/users/:id" component={User} />
            <Route path="/settings" component={Settings} />
            <Route path="/admin" component={Admin} />

            <Route path="/reg" component={RegistrationRedirector} />

            {/* The old '/search' route falls through to the default */}
            <Route>
              <Redirect to="/" />
            </Route>
          </Switch>
        </AppContext.Provider>
      </RelayEnvironmentProvider>
    </div>
  );
}
