import React from 'react';
// import PropTypes from 'prop-types';
import { useQuery } from 'relay-hooks';
import graphql from 'babel-plugin-relay/macro';

import { Button, Col, ListGroup, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

import { formatDateTime, formatDateTimeFull } from '../../util/dates';
import DebugDump from '../../components/DebugDump';

export default function Tokens() {
  const { props: queryResult, error: queryError } = useQuery(
    graphql`
      query Tokens_Query {
        profile: currentUser {
          role
          tokens {
            nodes {
              createdAt
              role
              enabled
              revokedAt
              tokenHash
            }
          }
        }
      }
    `,
  );

  const tokens =
    queryResult?.profile?.tokens?.nodes?.length > 0
      ? queryResult.profile.tokens.nodes
      : null;

  return (
    <>
      {queryError ? (
        <>
          <div>Error: {queryError.message}</div>
          <div>Source: {JSON.stringify(queryError.source)}</div>
        </>
      ) : !queryResult ? (
        <div>Loading...</div>
      ) : (
        <>
          <Helmet title="Your tokens" />
          <Row className="border-bottom mb-3">
            <Col>
              <h3>API Tokens</h3>
            </Col>
            <Col xs="auto">
              <Button variant="outline-success" size="sm" disabled title="NYI">
                Generate new token
              </Button>
              <Button
                variant="outline-danger"
                size="sm"
                disabled
                title="NYI"
                className="ml-2"
              >
                Delete all
              </Button>
            </Col>
          </Row>
          <p>
            Tokens you have generated that can be used to access the Pedigree
            API.
          </p>
          {tokens && (
            <small>
              <ListGroup>
                {tokens.map((t) => (
                  <ListGroup.Item key={t.tokenHash}>
                    <Row>
                      <Col className="pt-1">
                        created: {formatDateTimeFull(t.createdAt)} —{' '}
                        <i>{t.role}</i>
                      </Col>
                      <Col xs="auto" className="pt-1">
                        {t.enabled ? 'enabled' : 'disabled'},{' '}
                        {t.revokedAt
                          ? formatDateTime(t.revokedAt)
                          : 'not revoked'}
                      </Col>
                      <Col xs="auto">
                        <Button
                          variant="outline-danger"
                          size="sm"
                          disabled
                          title="NYI"
                        >
                          Delete
                        </Button>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </small>
          )}
          <DebugDump level={1} object={queryResult} />
        </>
      )}
    </>
  );
}
