/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type Sex = "F" | "M" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type ReversePedigreeTree_dog$ref: FragmentReference;
declare export opaque type ReversePedigreeTree_dog$fragmentType: ReversePedigreeTree_dog$ref;
export type ReversePedigreeTree_dog = {|
  +id: string,
  +registeredName: string,
  +sex: ?Sex,
  +prefixes: ?$ReadOnlyArray<?string>,
  +suffixes: ?$ReadOnlyArray<?string>,
  +birthdate: ?string,
  +damId: ?string,
  +sireId: ?string,
  +descendents: {|
    +nodes: $ReadOnlyArray<{|
      +dog: ?{|
        +id: string,
        +registeredName: string,
        +sex: ?Sex,
        +prefixes: ?$ReadOnlyArray<?string>,
        +suffixes: ?$ReadOnlyArray<?string>,
        +birthdate: ?string,
        +damId: ?string,
        +sireId: ?string,
      |}
    |}>
  |},
  +$refType: ReversePedigreeTree_dog$ref,
|};
export type ReversePedigreeTree_dog$data = ReversePedigreeTree_dog;
export type ReversePedigreeTree_dog$key = {
  +$data?: ReversePedigreeTree_dog$data,
  +$fragmentRefs: ReversePedigreeTree_dog$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registeredName",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sex",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "prefixes",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "suffixes",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "birthdate",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "damId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sireId",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": 3,
      "kind": "LocalArgument",
      "name": "depth"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReversePedigreeTree_dog",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    (v3/*: any*/),
    (v4/*: any*/),
    (v5/*: any*/),
    (v6/*: any*/),
    (v7/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "depth",
          "variableName": "depth"
        }
      ],
      "concreteType": "AncestorResultsConnection",
      "kind": "LinkedField",
      "name": "descendents",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AncestorResult",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Dog",
              "kind": "LinkedField",
              "name": "dog",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/),
                (v4/*: any*/),
                (v5/*: any*/),
                (v6/*: any*/),
                (v7/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Dog",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ae254e7ac028eb8887550eed64e86d3e';

module.exports = node;
