/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ReversePedigreeTree_dog$ref = any;
export type ReversePedigree_QueryVariables = {|
  id: string,
  depth: number,
|};
export type ReversePedigree_QueryResponse = {|
  +dog: ?{|
    +$fragmentRefs: ReversePedigreeTree_dog$ref
  |}
|};
export type ReversePedigree_Query = {|
  variables: ReversePedigree_QueryVariables,
  response: ReversePedigree_QueryResponse,
|};
*/


/*
query ReversePedigree_Query(
  $id: Id!
  $depth: Int!
) {
  dog(id: $id) {
    ...ReversePedigreeTree_dog_1gqN78
  }
}

fragment ReversePedigreeTree_dog_1gqN78 on Dog {
  id
  registeredName
  sex
  prefixes
  suffixes
  birthdate
  damId
  sireId
  descendents(depth: $depth) {
    nodes {
      dog {
        id
        registeredName
        sex
        prefixes
        suffixes
        birthdate
        damId
        sireId
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "depth"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v3 = [
  {
    "kind": "Variable",
    "name": "depth",
    "variableName": "depth"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "registeredName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sex",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "prefixes",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "suffixes",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "birthdate",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "damId",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sireId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ReversePedigree_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Dog",
        "kind": "LinkedField",
        "name": "dog",
        "plural": false,
        "selections": [
          {
            "args": (v3/*: any*/),
            "kind": "FragmentSpread",
            "name": "ReversePedigreeTree_dog"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ReversePedigree_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Dog",
        "kind": "LinkedField",
        "name": "dog",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "AncestorResultsConnection",
            "kind": "LinkedField",
            "name": "descendents",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AncestorResult",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Dog",
                    "kind": "LinkedField",
                    "name": "dog",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0bea37d8f56b1e21dcb15daf9706d492",
    "id": null,
    "metadata": {},
    "name": "ReversePedigree_Query",
    "operationKind": "query",
    "text": "query ReversePedigree_Query(\n  $id: Id!\n  $depth: Int!\n) {\n  dog(id: $id) {\n    ...ReversePedigreeTree_dog_1gqN78\n  }\n}\n\nfragment ReversePedigreeTree_dog_1gqN78 on Dog {\n  id\n  registeredName\n  sex\n  prefixes\n  suffixes\n  birthdate\n  damId\n  sireId\n  descendents(depth: $depth) {\n    nodes {\n      dog {\n        id\n        registeredName\n        sex\n        prefixes\n        suffixes\n        birthdate\n        damId\n        sireId\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '40050ab05f068dc1d751cf9013dfea86';

module.exports = node;
