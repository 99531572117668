import React, { useState } from 'react';

import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Container from 'react-bootstrap/Container';

import TradingCard from '../../dogs/TradingCard';

// This is the default set of dogs (all of the dogs which have won
// awards).  Even better would be to query to get the list, but this
// is just an MVP for now.
const defaultLead =
  'The following is the generated trading card data for the 2018 EBC specialty';

const defaultDogIds = [
  'vp1JP', // Tennji's Hatari                     1969
  'Me01L', // Tesseract Princess Pandora          1970
  '6GqwW', // Tanda's Pagan Baron                 1971
  'MLYRO', // Questt o' A-Talisman                1972
  '6mgNg', // Rujaks Golden Oribi                 1973
  'bojrX', // Shadowbye's Mitty                   1974
  'vxY4z', // Tawelfan Lubilustre                 1975
  'M8aLJ', // Changa's Mamba Luckalot             1976     1977
  'vdmYd', // Libra's Apollo of Delahi            1978
  'jW5op', // Khajah's Gay Jessica                1979     1981
  '6jxKb', // Nordayl's Daily Double              1980
  'MaJLP', // Sonbar's Brandy                     1982
  '6G9Kl', // Amun Red Nile Princess              1983
  'VLLbd', // Rameses Golden Phoenix              1984     1985
  'MR7GY', // Anasazi's Whidbey Osiris            1986     1987
  '60Vgw', // Kenobi's Obi-One at Gendina         1988
  'xGraw', // Vikentor's Country Rose             1989
  '3X2Jx', // Fantasia's Fifth Amendment          1990     1991
  '3Xpx3', // Abutu's Cosmo Topper                1992
  '9baND', // Akuaba's Tornado                    1993
  'MLraV', // Arubmec's The Victor                1994
  'RZ4aG', // Arubmec's Overdrive To My-Tym       1995(a)
  'vA8AW', // Calaz Executive Of Em Basi          1995(b)
  '60ZOd', // Fantasia's Flambe                   1996     1999
  'AVnyR', // Penda Taji's Tri-Umphant One        1997
  'eWAkn', // My-Tym Waits For No One             1998
  'VLx0w', // Zuri's Nobantu                      2000
  'Xy42W', // Arubmec's Encore                    2001
  'gRaLo', // Nyanga Signet Sweet William         2002
  '3oKqr', // Bordeaux African Jumoke             2003
  'rGlK5', // Zuri's Mugabe                       2004
  'Z99j2', // Zindika Svengali Daring Dillin      2005
  'dXZNm', // Berimo Taji Start Me Up             2006
  'pKmkG', // Platinum's Adrenaline Rush          2007
  'ajxj8', // Jasiri-Sukari Congo-Leezza Rice     2008
  '5XYda', // Taji's Rayne Carnation              2009
  'AVxyd', // Emerant's The Navigator             2010
  'ajOpe', // Atarasi's D'Lucks Edition           2011(b)  2013(a)
  'rGZ0D', // Taji's Klassic Lil Black Dress      2011(a)  2012(b)  2016(b)
  'Korzo', // Jasiri-Sukari Win Tin Tin           2012(a)  2013(b)  2014(b)
  'oW1lo', // Platinum's Fearisnotafactor         2014(a)
  'kgOEO', // Arubmec's Take A Chance On Me       2015(a)  2017(a)
  'WLNVe', // Signet Meisterhaus Gossip Girl      2015(b)
  'dXL7z', // Mibre's Jedi Knight Luke Skywalker  2016(a)
  'WLKKR', // Taji's Don'T Believe Me Just Watch  2017(b)
];

const extraLead = 'These are extra trading card dogs, just because.';
const extraDogIds = [
  'Korm0', // Taji's Alster Echo - Echo
  'WLapR', // Taji's Alster Ego From Hodari - Zoe
  '37KB8', // Taji Alster When The Clock Strikes Twelve
  'eWkqO', // Taji Alster Twelfth Knight
  'LzLaJ', // Taji Alster Until The Twelfth Of Never
  'AVnZ2', // Taji Alster's Twelfth Moon - Juno

  'AV082', // Taji's hAfrican Queen Disa - Disa
  'OPQ4Q', // Taji and Ture's Strings of Fire - Banjo
  'Z9amk', // Ture and Taji Dance with Delilah - Delilah
  'Xy25x', // Taji & Ture's G.Wiz R U Sirius? - Gable
  'RZalx', // Taji's Midnight Cowboy Rides with Ture - Cowboy (Too)
  'wYEbp', // Ture & Taji's Sirius Piece Of Work - Dresden
];

export default function TradingCards() {
  const [extraCards, setExtraCards] = useState(false);
  const [plainText, setPlainText] = useState(false);

  const lead = !extraCards ? defaultLead : extraLead;
  const dogIds = !extraCards ? defaultDogIds : extraDogIds;

  return (
    <div>
      <Container className="mt-4">
        <h1>Trading Cards</h1>

        <div className="mt-4 mb-4">
          <ButtonGroup>
            <Button onClick={() => setExtraCards(false)} active={!extraCards}>
              Regular cards
            </Button>
            <Button onClick={() => setExtraCards(true)} active={extraCards}>
              Extra cards
            </Button>
          </ButtonGroup>{' '}
          <ButtonGroup>
            <Button onClick={() => setPlainText(false)} active={!plainText}>
              Formatted
            </Button>
            <Button onClick={() => setPlainText(true)} active={plainText}>
              Plain text
            </Button>
          </ButtonGroup>
        </div>

        <p className="lead">{lead}</p>
      </Container>

      <Container className="mt-4">
        {dogIds.map((dogId) => (
          <TradingCard key={dogId} dogId={dogId} plainText={plainText} />
        ))}
      </Container>
    </div>
  );
}
