/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Users_QueryVariables = {||};
export type Users_QueryResponse = {|
  +users: ?{|
    +nodes: $ReadOnlyArray<{|
      +id: string,
      +createdAt: string,
      +updatedAt: string,
      +firstName: string,
      +lastName: string,
      +fullName: ?string,
      +email: ?string,
      +gravatar: ?string,
      +role: ?string,
    |}>
  |}
|};
export type Users_Query = {|
  variables: Users_QueryVariables,
  response: Users_QueryResponse,
|};
*/


/*
query Users_Query {
  users(orderBy: FIRST_NAME_ASC) {
    nodes {
      id
      createdAt
      updatedAt
      firstName
      lastName
      fullName
      email
      gravatar
      role
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "orderBy",
        "value": "FIRST_NAME_ASC"
      }
    ],
    "concreteType": "UsersConnection",
    "kind": "LinkedField",
    "name": "users",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fullName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "gravatar",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "role",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": "users(orderBy:\"FIRST_NAME_ASC\")"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "Users_Query",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "Users_Query",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "6d59bb3df2a152ec9926b0c4c8a66f00",
    "id": null,
    "metadata": {},
    "name": "Users_Query",
    "operationKind": "query",
    "text": "query Users_Query {\n  users(orderBy: FIRST_NAME_ASC) {\n    nodes {\n      id\n      createdAt\n      updatedAt\n      firstName\n      lastName\n      fullName\n      email\n      gravatar\n      role\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7865c68e4bd14bc53b725153824ef413';

module.exports = node;
