import React from 'react';
// import PropTypes from 'prop-types';
import { useQuery } from 'relay-hooks';
import graphql from 'babel-plugin-relay/macro';
import { useParams } from 'react-router-dom';

import Container from 'react-bootstrap/Container';

import PedigreeTree from '../../dogs/PedigreeTree';

// import DebugDump from '../components/DebugDump';

const generationsToShow = 5;
const treeWidth = 1500;
const treeHeight = 1200;

export default function Pedigree() {
  const { id } = useParams();

  const { props: queryResult, error: queryError } = useQuery(
    graphql`
      query Pedigree_Query($id: Id!, $depth: Int!) {
        dog(id: $id) {
          ...PedigreeTree_dog @arguments(depth: $depth)
        }
      }
    `,
    {
      id,
      depth: generationsToShow,
    },
  );

  const { dog } = queryResult ?? {};

  return (
    <Container fluid={false}>
      {queryError ? (
        <>
          <div>Error: {queryError.message}</div>
          <div>Source: {JSON.stringify(queryError.source)}</div>
        </>
      ) : !queryResult ? (
        <div>Loading...</div>
      ) : (
        <PedigreeTree
          width={treeWidth}
          height={treeHeight}
          dog={dog}
          otherDog={null}
          generations={generationsToShow}
        />
      )}
    </Container>
  );
}
