import React from 'react';
import PropTypes from 'prop-types';
import { useFragment } from 'relay-hooks';
import graphql from 'babel-plugin-relay/macro';

import { NavLink } from 'react-router-dom';
import NavItem from 'react-bootstrap/NavItem';

import SearchDogsResultTitles from './SearchDogsResultTitles';

import DebugDump from '../components/DebugDump';
import smartquotes from 'smartquotes';

/**
 * Renders a single search result.
 */
export default function SearchDogsResultItem({ item: itemProp, itemLink }) {
  const item = useFragment(
    graphql`
      fragment SearchDogsResultItem_item on SearchDogsResult {
        dogId
        registeredName
        callName
        registrations
        prefixes
        suffixes
        dog {
          titles {
            totalCount
          }
        }
        ...SearchDogsResultTitles_item
      }
    `,
    itemProp,
  );

  // console.log(`rendering search result (item): ${JSON.stringify(item)}`);

  const name = smartquotes(item.registeredName);
  const callName = item.callName ? ` (${smartquotes(item.callName)})` : '';
  // only include registrations if there's a hit...
  const registrations = item.registrations?.includes?.('<b>')
    ? ` (${item.registrations})`
    : '';

  const link = itemLink ? itemLink(item) : `/dogs/${item.dogId}`;

  // const hasTitles = item.dog.titles.totalCount > 0;
  const hasTitles = item.prefixes?.length > 0 || item?.suffixes?.length > 0;

  return (
    <NavItem className="m-2">
      <NavLink className="nav-link d-inline-block p-0" to={link}>
        <span dangerouslySetInnerHTML={{ __html: name }} />
        <span dangerouslySetInnerHTML={{ __html: callName }} />
        <span
          className="small"
          dangerouslySetInnerHTML={{ __html: registrations }}
        />
      </NavLink>
      {hasTitles && (
        <div className="small text-muted">
          <SearchDogsResultTitles side="prefix" item={item} />
          <span dangerouslySetInnerHTML={{ __html: name }} />
          <SearchDogsResultTitles side="suffix" item={item} />
        </div>
      )}
      <DebugDump level={2} object={item} />
    </NavItem>
  );
}

SearchDogsResultItem.propTypes = {
  /** fulfilled by Relay fragment */
  item: PropTypes.object,
  /** formatter for dog link (to customize the destination) */
  itemLink: PropTypes.func,
};
