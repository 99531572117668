import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useFragment } from 'relay-hooks';
import graphql from 'babel-plugin-relay/macro';

import D3 from '../components/D3';
import * as d3 from 'd3';
import multiParentHierarchy from '../d3/multiParentHierarchy';

import smartquotes from 'smartquotes';

import './PedigreeTree.scss';

/**
 * A beautiful D3/SVG pedigree tree.
 */
export default function PedigreeTree({
  width,
  height,
  dog: dogProp,
  otherDog: otherDogProp,
  generations,
}) {
  // We *might* be able to combine the fragments, once the explorer starts
  // working correctly.
  const dog = useFragment(
    graphql`
      fragment PedigreeTree_dog on Dog
      @argumentDefinitions(depth: { type: "Int", defaultValue: 5 }) {
        id
        sex
        ancestors(depth: $depth) {
          nodes {
            dog {
              id
              registeredName
              sex
              prefixes
              suffixes
              birthdate
              damId
              sireId
            }
          }
        }
      }
    `,
    dogProp,
  );

  const otherDog = useFragment(
    graphql`
      fragment PedigreeTree_otherDog on Dog
      @argumentDefinitions(depth: { type: "Int", defaultValue: 5 }) {
        id
        sex
        ancestors(depth: $depth) {
          nodes {
            dog {
              id
              registeredName
              sex
              prefixes
              suffixes
              birthdate
              damId
              sireId
            }
          }
        }
      }
    `,
    otherDogProp,
  );

  const root = useRef(null);

  const update = useCallback(
    (svg) => {
      // console.log('PedigreeTree update...', this.props);

      if (!dog) {
        return;
      }

      // reduce the dogs to a map...
      let dogs = dog.ancestors.nodes.reduce((acc, val) => {
        acc[val.dog.id] = {
          ...val.dog,
          prefix: (val.dog.prefixes || []).join(' '),
          suffix: (val.dog.suffixes || []).join(' '),
        };
        return acc;
      }, {});

      // if there's an "otherDog", we're showing a theoretical pedigree
      if (otherDog) {
        // there *might* be overlap in the data, but in that case the info
        // should be the same anyway...
        dogs = otherDog.ancestors.nodes.reduce((acc, val) => {
          acc[val.dog.id] = {
            ...val.dog,
            prefix: (val.dog.prefixes || []).join(' '),
            suffix: (val.dog.suffixes || []).join(' '),
          };
          return acc;
        }, dogs);
      }

      // if there's an "otherDog", create a fictitious child...
      if (otherDog) {
        // determine who's the sire and who's the dam
        let sireId = dog.id;
        let damId = otherDog.id;
        if (dog.sex === 'F' || (!dog.sex && otherDog.sex === 'M')) {
          sireId = otherDog.id;
          damId = dog.id;
        }

        dogs['(progeny)'] = {
          registeredName: '(progeny)',
          sireId,
          damId,
        };
      }

      // add cross-references for sires and dams...
      Object.values(dogs).forEach((dog) => {
        dog.dam = dogs[dog.damId];
        dog.sire = dogs[dog.sireId];
      });

      const rootDog = dogs[otherDog ? '(progeny)' : dog.id];

      // multiParentHierarchy is a d3-hierarchy-like variation that can
      // accomodate things that happen in pedigrees, like line breeding.
      const hier = multiParentHierarchy(
        rootDog,
        (dog, depth) => {
          // If we've hit our generation limit, or this dog has no parents,
          // just return null and bail.
          if (depth >= generations - 1 || (!dog.sire && !dog.dam)) {
            return null;
          }
          const hierChildren = [];
          if (dog.sire) {
            hierChildren.push(dog.sire);
          }
          if (dog.dam) {
            hierChildren.push(dog.dam);
          }
          return hierChildren;
        },
        (dog) => dog.id,
        (dog) => `${smartquotes(dog.registeredName)} (${dog.id})`,
      );

      // console.log(`hier: ${hier}`);

      // const { width, height } = this.state;
      const actualGenerations = hier.height + 1;
      const columnWidth = width / actualGenerations;
      const bracketWidth = columnWidth / 10;
      const generationWidth = columnWidth - bracketWidth;

      // The docs aren't clear about this but the first dimension (x) is the
      // peer separation one (which we use vertically), and the second
      // dimension (y) is the generational one (which we use horizontally.)
      // So, when you see 'x' and 'y', everything looks backwards here!
      const tree = d3
        .tree()
        .separation((a, b) => (a.parent === b.parent ? 1 : 1.25))
        .size([height, width - generationWidth]);

      tree(hier);

      const nodes = hier.descendants();
      const links = hier.links();

      // Check nodes to see if they're too close... ?
      // if (height < 1200) {
      //   this.setState({
      //     height: 1200,
      //   });
      //   return;
      // }

      // console.log(`tree: ${tree}`);
      root.current
        .selectAll('.link')
        .data(links)
        .enter()
        .append('path')
        .attr('class', (l) => `link sex-${l.target.data.sex || 'unknown'}`)
        // start the link *after* the dog/node's underline, which is
        // done later
        .attr(
          'd',
          (l, i) =>
            `M ${l.source.y + generationWidth},${l.source.x} C ${
              l.source.y + columnWidth
            },${l.source.x} ${l.target.y - bracketWidth},${l.target.x} ${
              l.target.y
            },${l.target.x}`,
        );

      const node = root.current
        .selectAll('.node')
        .data(nodes)
        .enter()
        .append('g')
        .attr('class', 'node')
        .attr('transform', (n) => {
          // console.log(`node param: ${n}`);
          return `translate(${n.y}, ${n.x})`;
        });

      node
        .append('a')
        .attr('transform', 'translate(2, -5)')
        .attr('href', (n) => `/dogs/${n.data.id}/pedigree`)
        .append('text')
        .attr('class', 'name wrap-name')
        .text((n) => smartquotes(n.data.registeredName));

      node
        .append('path')
        .attr('class', (d) => `under sex-${d.data.sex || 'unknown'}`)
        .attr('d', (n, i) => `M 0,0 H ${generationWidth}`);

      node
        .append('text')
        .attr('class', 'extra titles')
        .attr('transform', 'translate(2,15)')
        .text((n) => `${n.data.prefix || '-'} / ${n.data.suffix || '-'}`);

      node
        .append('text')
        .attr('class', 'extra birth')
        .attr('transform', 'translate(2,30)')
        .text((n) => `${n.data.birthdate || ''}`);

      // node.append('text')
      //     .attr('class', 'extra wrap-titles')
      //     .attr('transform', 'translate(2,15)')
      //     .text(n => `${n.data.prefix || '-'} / ${n.data.suffix || '-'} / ${n.data.birthDate}`);
      const wrapFn = wrapName(generationWidth);
      root.current.selectAll('text.wrap-name').each(wrapFn);

      // this.root.selectAll('text.wrap-titles')
      //     .each(wrapTitles);
    },
    [width, height, dog, otherDog, generations],
  );

  const setup = useCallback(
    (svg) => {
      // console.log('PedigreeTree setup...');
      root.current = d3
        .select(svg)
        .attr('preserveAspectRatio', 'xMinYMin meet')
        .attr('viewBox', `0 0 ${width} ${height}`)
        .append('g')
        .attr('class', 'pedigree');

      // If we already have a dog, force an update...
      if (dog) {
        update(svg);
      }
    },
    [width, height, dog, update],
  );

  return <D3 width="100%" onSetup={setup} onUpdate={update} />;
}

// The function passed to d3's 'each' captures the 'this' value... so we need
// to curry-in the width we want and create the wrapping function on the fly.
function wrapName(width) {
  return function wrapName2(d) {
    wrapInner.call(this, d, width, 18, true, 'wrap-name');
  };
}

// function wrapTitles(d) {
//     wrapInner.call(this, d, 150, 15, false, 'wrap-titles');
// }

function wrapInner(d, wrapWidth, lineHeight, wrapUp, classes) {
  const root = d3.select(this);
  const orig = root.text();
  const lines = [];
  const words = orig.split(' ');

  let textWidth = this.getComputedTextLength();
  let remaining = words;

  while (remaining.length > 0) {
    // calculate a new line...
    let used = 0;
    let test = null;
    // console.log(`${textWidth}: ${test}`);
    for (used = remaining.length /*used > 1*/; ; used--) {
      test = remaining.slice(0, used).join(' ');
      root.text(test);
      textWidth = this.getComputedTextLength();
      // console.log(`${textWidth}: ${test} (remaining: "${remaining.slice(used).join(' ')}")`);

      if (textWidth <= wrapWidth) {
        break;
      }

      if (used === 1) {
        // we can't get any smaller, bail!
        // TODO: ellide/clip?
        break;
      }
    }

    lines.push(test);
    remaining = remaining.slice(used);
  }

  // console.log(`lines: ["${lines.join('","')}"]`);

  // Now replace the existing text with the new lines...
  const textRoot = root.text(null).classed(classes, false);

  let i = 0;
  const max = lines.length;
  lines.forEach((line) => {
    textRoot
      .append('tspan')
      .attr('x', '0')
      .attr('y', (wrapUp ? i - max + 1 : i) * lineHeight)
      .text(line);
    i++;
  });
}

PedigreeTree.propTypes = {
  /** a relative width, in proportion to the height (I think) */
  width: PropTypes.number.isRequired,
  /** a relative height, in proportion to the width (I think) */
  height: PropTypes.number.isRequired,
  /** fulfilled by Relay fragment */
  dog: PropTypes.object,
  /** fulfilled by Relay fragment */
  otherDog: PropTypes.object,
  /** the number of generations to show (before line-breeding impacts things) */
  generations: PropTypes.number.isRequired,
};
