// import * as d3 from 'd3';
import { hierarchy } from 'd3';

// class MultiParentNode extends d3.hierarchy.prototype.constructor {
class MultiParentNode extends hierarchy.prototype.constructor {
  // d3's Node constructor doesn't take anything other than data, but then
  // immediate and always sets the depth and parent.  We make these
  // constructor paramters for convenenience.
  constructor(data, parent, depth) {
    super(data);
    this.addParentAndDepth(parent, depth);
    this.minHeight = 0;
    this.maxHeight = 0;
  }

  addParentAndDepth(parent, depth) {
    let changedParent = false;
    let minDepthFromParent = 0;
    let maxDepthFromParent = 0;
    if (parent) {
      if (!this.allParents) {
        this.allParents = [];
      }

      if (!this.allParents.includes(parent)) {
        this.allParents.push(parent);
        this.allParents.sort((a, b) => b.maxDepth - a.maxDepth);

        if (this.parent !== this.allParents[0]) {
          this.parent = this.allParents[0];
          changedParent = true;
          minDepthFromParent = this.parent.minDepth + 1;
          maxDepthFromParent = this.parent.maxDepth + 1;
        }
      }
    }

    this.updateDepth(
      Math.min(depth, minDepthFromParent),
      Math.max(depth, maxDepthFromParent),
    );

    if (changedParent) {
      this._forAllParents(parent => parent.updateImmediateChildren());
    }
  }

  // Updates depth of this node and all children
  updateDepth(minDepth, maxDepth) {
    this.minDepth = Math.min(this.minDepth || 0, minDepth);
    this.maxDepth = Math.max(this.maxDepth || 0, maxDepth);
    this.depth = this.maxDepth;

    this._forAllChildren(child =>
      child.updateDepth(this.minDepth + 1, this.maxDepth + 1),
    );
  }

  // updates height of this node and all parents
  updateHeight(minHeight, maxHeight) {
    this.minHeight = Math.min(this.minHeight, minHeight);
    this.maxHeight = Math.max(this.maxHeight, maxHeight);
    this.height = this.maxHeight;
    this._forAllParents(parent =>
      parent.updateHeight(this.minHeight + 1, this.maxHeight + 1),
    );
  }

  addChild(child) {
    if (!this.allChildren) {
      this.allChildren = [];
    }
    if (!this.allChildren.includes(child)) {
      this.allChildren.push(child);
      this.updateHeight(child.minHeight + 1, child.maxHeight + 1);
    }
  }

  updateImmediateChildren() {
    if (!this.allChildren) {
      this.children = null;
      return;
    }

    const children = this.allChildren.filter(c => c.parent === this);
    this.children = children.length > 0 ? children : null;

    this._forAllChildren(child =>
      child.updateDepth(this.minDepth + 1, this.maxDepth + 1),
    );
  }

  links() {
    // Override hierarchy's default implementation of links, because we
    // need to create additional ones!
    const links = [];
    this.each(n =>
      n._forAllParents(parent =>
        links.push({
          source: parent,
          target: n,
        }),
      ),
    );
    return links;
  }

  _forAllChildren(fn) {
    if (!this.allChildren) {
      return;
    }

    this.allChildren.forEach(fn);
  }

  _forAllParents(fn) {
    if (!this.allParents) {
      return;
    }

    this.allParents.forEach(fn);
  }

  _dumpDebug(displayFn, label) {
    console.log(
      `${(label && '' + label + ' ') || ''}depth ${this.depth}: ${displayFn(
        this.data,
      )} d: ${this.minDepth}/${this.maxDepth}, h: ${this.height}/${
        this.minHeight
      }/${this.maxHeight}, parents: ${JSON.stringify(
        this.parents && this.parents.map(n => displayFn(n.data)),
      )}, children: ${JSON.stringify(
        this.children && this.children.map(n => displayFn(n.data)),
      )}, all children: ${JSON.stringify(
        this.allChildren && this.allChildren.map(n => displayFn(n.data)),
      )}`,
    );
  }
}

export default function multiParentHierarchy(
  data,
  childrenFn,
  keyFn,
  displayFn,
) {
  displayFn = displayFn || keyFn;
  const dedupMap = {};
  const root = multiParentHelper(
    null,
    data,
    0,
    childrenFn,
    keyFn,
    displayFn,
    dedupMap,
  );
  // console.log('=== done building hierarchy... ===');
  // root.each(n => n._dumpDebug(displayFn, 'FINAL'));
  // console.log('=== done building hierarchy ===');
  return root;
}

function multiParentHelper(
  parent,
  data,
  depth,
  childrenFn,
  keyFn,
  displayFn,
  dedupMap,
) {
  displayFn = displayFn || keyFn;
  const key = keyFn(data);
  let node = dedupMap[key];

  if (!node) {
    node = new MultiParentNode(data, parent, depth);
    // node._dumpDebug(displayFn, 'NEW');
    dedupMap[key] = node;
  } else {
    node.addParentAndDepth(parent, depth);
    // node._dumpDebug(displayFn, 'PAR');
  }

  const children = childrenFn(data, depth);
  (children || []).forEach(child => {
    const childNode = multiParentHelper(
      node,
      child,
      depth + 1,
      childrenFn,
      keyFn,
      displayFn,
      dedupMap,
    );
    node.addChild(childNode);
  });
  node.updateImmediateChildren();

  // node._dumpDebug(displayFn, '<--');
  return node;
}
