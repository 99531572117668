import { DateTime, Duration } from 'luxon';
import pluralize from 'pluralize';

export function luxonify(date) {
  if (!date) {
    return date;
  }

  if (date instanceof DateTime) {
    return date;
  }

  if (date instanceof Date) {
    return DateTime.fromJSDate(date);
  }

  switch (typeof date) {
    case 'number':
      return DateTime.fromMillis(date);
    case 'string':
      return DateTime.fromISO(date);
    case 'object':
      return DateTime.fromObject(date);

    default:
      throw new Error(`unexpected date type: "${typeof date}"`);
  }
}

export function formatDateTime(date) {
  return luxonify(date)?.toLocaleString(DateTime.DATETIME_SHORT);
}

export function formatDateTimeFull(date) {
  return luxonify(date)?.toLocaleString(DateTime.DATETIME_FULL);
}

export function formatDate(date) {
  return luxonify(date)?.toLocaleString(DateTime.DATE_SHORT);
}

export function formatDateFull(date) {
  return luxonify(date)?.toLocaleString(DateTime.DATE_FULL);
}

export function relativize(date) {
  return luxonify(date)?.toRelative();
}

export function durationify(duration) {
  if (!duration) {
    return duration;
  }

  if (duration instanceof Duration) {
    return duration;
  }

  switch (typeof duration) {
    case 'number':
      return Duration.fromMillis(duration);
    case 'string':
      return Duration.fromISO(duration);
    case 'object':
      return Duration.fromObject(duration);
    default:
      throw new Error(`unexpected duration type: "${typeof duration}"`);
  }
}

function exists(x) {
  return !!x;
}

export function humanizeDuration(duration, largestUnit) {
  if (!duration) {
    return '(unknown duration)';
  }

  const units = ['years', 'months', 'days', 'hours', 'minutes', 'seconds'];
  let usedUnits = units;
  if (largestUnit) {
    usedUnits = [...units];
    while (usedUnits[0] !== largestUnit) {
      usedUnits.shift();
    }
  }

  const durObj = durationify(duration)
    .shiftTo(...usedUnits)
    .toObject();
  const parts = usedUnits
    .map((unit) => (durObj[unit] ? pluralize(unit, durObj[unit], true) : null))
    .filter(exists);

  return parts.join(', ');
}
