import React from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter } from 'react-router-dom';

import App from './App';

import pkgInfo from '../package.json';

// import 'bootstrap/dist/css/bootstrap.css';

const { name, version, description } = pkgInfo;

ReactDOM.render(
  <BrowserRouter>
    {/*
      Is it weird to have the name/version/description passed in from the
      outside?  App itself *could* leverage package.json in the same way!
    */}
    <App appName={name} appVersion={version} appDesc={description} />
  </BrowserRouter>,
  document.getElementById('root'),
);
