import React from 'react';
import PropTypes from 'prop-types';
import { useFragment } from 'relay-hooks';
import graphql from 'babel-plugin-relay/macro';

import { Link } from 'react-router-dom';

import smartquotes from 'smartquotes';

/**
 * The standard rendering of a dog with the link to their full page.
 */
export default function DogLink({ dog: dogProp }) {
  const dog = useFragment(
    graphql`
      fragment DogLink_dog on Dog {
        id
        registeredName
        callName
        prefixes
        suffixes
      }
    `,
    dogProp,
  );
  const prefix = dog.prefixes.length > 0 ? dog.prefixes.join(' ') : null;
  const suffix = dog.suffixes.length > 0 ? dog.suffixes.join(' ') : null;

  const titles =
    prefix || suffix ? `${prefix || '-'} / ${suffix || '-'}` : null;

  return (
    <>
      <Link to={`/dogs/${dog.id}`}>{smartquotes(dog.registeredName)}</Link>
      {titles && (
        <>
          {' '}
          <small className="text-muted">({titles})</small>
        </>
      )}
    </>
  );
}

DogLink.propTypes = {
  /** fulfilled by Relay fragment */
  dog: PropTypes.object,
};
