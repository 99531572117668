import React from 'react';
// import PropTypes from 'prop-types';
import { useQuery } from 'relay-hooks';
import graphql from 'babel-plugin-relay/macro';

import { Link } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';

import { Helmet } from 'react-helmet';

import { prettifyRole } from '../../util/roles';

import DataTable from '../../components/DataTable';
import DebugDump from '../../components/DebugDump';

export default function Users() {
  const { props: queryResult, error: queryError } = useQuery(
    graphql`
      query Users_Query {
        users(orderBy: FIRST_NAME_ASC) {
          nodes {
            id
            createdAt
            updatedAt
            firstName
            lastName
            fullName
            email
            gravatar
            role
          }
        }
      }
    `,
  );

  return (
    <>
      <Helmet title="All users" />
      <Container className="mt-4">
        <h2 className="mb-3">All users</h2>
        {queryError ? (
          <>
            <div>Error: {queryError.message}</div>
            <div>Source: {JSON.stringify(queryError.source)}</div>
          </>
        ) : !queryResult ? (
          <div>Loading...</div>
        ) : (
          <>
            <DataTable
              columns={[
                {
                  label: 'Name',
                  fn: (d) => (
                    <Link to={`/users/${d.id}`}>
                      <Image
                        rounded
                        src={`https://www.gravatar.com/avatar/${d.gravatar}?d=identicon&s=30`}
                        className="mr-2 mt-n1"
                      />
                      {d.fullName}
                    </Link>
                  ),
                },
                {
                  label: 'Email',
                  fn: (d) => d.email,
                },
                {
                  label: 'Role',
                  fn: (d) => prettifyRole(d.role),
                },
              ]}
              standardExtraColumns
              data={queryResult?.users?.nodes}
            />
          </>
        )}
        <DebugDump level={1} object={queryResult} />
      </Container>
    </>
  );
}
