import React from 'react';
import PropTypes from 'prop-types';
import { useFragment } from 'relay-hooks';
import graphql from 'babel-plugin-relay/macro';

/**
 * The list of registration IDs (AKC, etc.) for the dog.
 */
export default function DogRegistrations({ dog: dogProp }) {
  const dog = useFragment(
    graphql`
      fragment DogRegistrations_dog on Dog {
        registrations {
          registry
          registration
        }
      }
    `,
    dogProp,
  );

  if (!dog.registrations || dog.registrations.length === 0) {
    return null;
  }

  return (
    <tr>
      <th scope="row">registrations:</th>
      <td>
        {dog.registrations
          .map((reg) => `${reg.registration} (${reg.registry})`)
          .join(', ')}
      </td>
    </tr>
  );
}

DogRegistrations.propTypes = {
  /** fulfilled by Relay fragment */
  dog: PropTypes.object,
};
