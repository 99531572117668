import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Markdown from 'markdown-to-jsx';
import styled from 'styled-components';

import CHANGELOG from '../CHANGELOG.md';

// We "downgrade" headings in the markdown so that they work nicely inside the
// page, by roughly 3: H1->H4, H2->H5, and H3->H6.  Also, links might be
// rendered as simple spans to avoid the public seeing the (private) source
// code.
const ChangelogH1 = styled.h4`
  margin-top: 2rem;
`;

const ChangelogH2 = styled.h5`
  &, .changelog h4 ~ & ~ & {
    margin-top: 2rem;
    padding-top: 0;
    border-top: none;
  }

  .changelog h4 ~ & {
    margin-top 3rem;
    padding-top: 3rem;
    border-top: 1px solid #6c757d;
  }
`;

const ChangelogH3 = styled.h6`
  margin-left: 1rem;
`;

const ChangelogANoLinks = styled.span.attrs({ href: null })``;
const ChangelogAAllowLinks = styled.a.attrs({ target: '_blank' })``;

async function fetchDefaultChangelogText() {
  const response = await fetch(CHANGELOG);
  // console.log('got changelog?', response);
  const text = await response.text();
  // console.log('got changelog text?', text);
  return text;
}

/** Renders standard CHANGELOG markdown. */
export default function Changelog({
  featureRelease,
  patchRelease,
  section,
  allowLinks,
  lineLimit,
  fetchText,
}) {
  const [changelog, setChangelog] = useState(null);

  useEffect(() => {
    async function fetchChangelog() {
      let text = await fetchText();

      if (lineLimit) {
        text = text.split('\n', lineLimit).join('\n');
      }

      setChangelog(text);
    }

    fetchChangelog();
  }, [fetchText, lineLimit]);

  if (!changelog) {
    return null;
  }

  return (
    <Markdown
      children={changelog}
      className="changelog"
      options={{
        overrides: {
          h1: featureRelease,
          h2: patchRelease,
          h3: section,
          a: allowLinks ? ChangelogAAllowLinks : ChangelogANoLinks,
        },
      }}
    />
  );
}

Changelog.propTypes = {
  /** component to render for major/minor feature releases */
  featureRelease: PropTypes.elementType,
  /** component to render for patch (fix) releases */
  patchRelease: PropTypes.elementType,
  /** component to render for section header ("fixes", "features", etc.)  */
  section: PropTypes.elementType,
  /** whether to allow links in the rendered changelog */
  allowLinks: PropTypes.bool,
  /** limit the number of lines shown */
  lineLimit: PropTypes.number,
  /** custom text fetcher (mainly for testing) */
  fetchText: PropTypes.func,
};

Changelog.defaultProps = {
  featureRelease: ChangelogH1,
  patchRelease: ChangelogH2,
  section: ChangelogH3,
  allowLinks: false,
  lineLimit: 0,
  fetchText: fetchDefaultChangelogText,
};
