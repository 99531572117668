import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

/** A simple inline search box. */
export default function SimpleSearchBox({
  placeholder,
  initialQuery,
  onChange,
  onSubmit,
}) {
  const [value, setValue] = useState(initialQuery ?? '');
  const [query, setQuery] = useState(value.trim());

  const handleChange = useCallback((event) => {
    const { value } = event.target;
    setValue(value);
    setQuery(value?.trim());
  }, []);

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      if (onSubmit) {
        onSubmit(query);
      }
    },
    [onSubmit, query],
  );

  useEffect(() => {
    if (onChange) {
      onChange(query);
    }
  }, [onChange, query]);

  return (
    <Form inline onSubmit={handleSubmit} className="mt-4 mb-4">
      <Form.Group className="w-100" controlId="searchBox">
        {/* Label has to be outside InputGroup to ensure the
                        left border on the input is rounded! */}
        <Form.Label className="sr-only">Name</Form.Label>
        <InputGroup className="w-100">
          <Form.Control
            type="text"
            name="query"
            placeholder={placeholder}
            autoComplete="off"
            value={value}
            onChange={handleChange}
          />
          <InputGroup.Append>
            <Button type="submit" variant="primary">
              Search
            </Button>
          </InputGroup.Append>
        </InputGroup>
      </Form.Group>
    </Form>
  );
}

SimpleSearchBox.propTypes = {
  /** a placeholder value for the input box */
  placeholder: PropTypes.string,
  /** an initial value for the search box */
  initialQuery: PropTypes.string,
  /** called every time the query (trimmed value) changes */
  onChange: PropTypes.func,
  /** called when the search form is submitted */
  onSubmit: PropTypes.func,
};

SimpleSearchBox.defaultProps = {
  placeholder: 'Name...',
};
