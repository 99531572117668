import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

import D3 from './D3';
import * as d3 from 'd3';

/**
 * A column-oriented bar chart for time-series (date-series) values.
 * Automatically fills in missing dates with zero-value (non) bars.
 */
export default function DayColumnsChart({ width, height, data }) {
  const bars = useRef(null);
  const xAxis = useRef(null);
  const yAxis = useRef(null);
  const tooltip = useRef(null);

  const update = useCallback(
    (svg) => {
      const margin = { top: 10, right: 0, bottom: 40, left: 40 };

      // Need to figure out min and max dates, and include *all* days in that
      // range, even if the data doesn't exist (i.e. count is zero)
      // We don't *really* need min/max calculations, as we know the query
      // returns the data already sorted.
      const days = d3.timeDay.range(
        data[0].day,
        d3.timeDay.offset(data[data.length - 1].day, 1),
        1,
      );

      // console.log('*****', data, days);

      const x = d3
        .scaleBand()
        .domain(days)
        .range([margin.left, width - margin.right])
        .padding(0.1)
        .align(0.5)
        .round(true);

      // We *do* need to find out what our maximum count is, though...
      const maxCount = d3.max(data, (d) => d.value);

      const y = d3
        .scaleLinear()
        .domain([0, maxCount])
        .range([height - margin.bottom, margin.top]);

      const xAxisFn = (g) =>
        g
          .attr('transform', `translate(0, ${height - margin.bottom})`)
          .call(
            d3
              .axisBottom(x)
              .tickFormat(d3.timeFormat('%-m/%-d'))
              .tickSizeOuter(0),
          );

      const yAxisFn = (g) =>
        g
          .attr('transform', `translate(${margin.left}, 0)`)
          .call(d3.axisLeft(y))
          .call((g) => g.select('domain').remove());

      xAxis.current
        .call(xAxisFn)
        .selectAll('text')
        .style('text-anchor', 'end')
        .attr('transform', 'rotate(-60)')
        .attr('dx', '-0.75em')
        .attr('dy', '0em');

      yAxis.current.call(yAxisFn);

      bars.current
        .selectAll('rect')
        .data(data)
        .join('rect')
        .attr('x', (d) => x(d.day))
        .attr('y', (d) => y(d.value))
        .attr('height', (d) => y(0) - y(d.value))
        .attr('width', x.bandwidth())
        .on('mouseover', (event, d) => {
          // console.log('MOUSEOVER', d);
          tooltip.current
            .attr(
              'transform',
              `translate(${x(d.day) + x.bandwidth() / 2}, ${Math.max(
                y(d.value) - 5,
                20,
              )})`,
            )
            .text(d.value);
        })
        .on('mouseout', (event, d) => {
          // console.log('MOUSEOUT', d);
          tooltip.current.text('');
        });
    },
    [width, height, data],
  );

  const setup = useCallback(
    (svg) => {
      const root = d3
        .select(svg)
        .attr('preserveAspectRatio', 'xMinYMin meet')
        .attr('viewBox', [0, 0, width, height]);

      const defs = root.append('defs');

      defs
        .append('filter')
        .attr('id', 'tooltip-shadow')
        .append('feDropShadow')
        .attr('dx', 0)
        .attr('dy', 0)
        .attr('stdDeviation', 1.5)
        .attr('flood-color', 'white')
        .attr('flood-opacity', 1);

      bars.current = root
        .append('g')
        // .attr('class', 'pedigree');  // CSS for fill?
        .attr('fill', 'hsl(195, 50%, 52%)');

      xAxis.current = root.append('g');
      yAxis.current = root.append('g');

      tooltip.current = root
        .append('text')
        .attr('text-anchor', 'middle')
        .attr('font-size', '12')
        .attr('font-weight', 'bold')
        .attr('filter', 'url(#tooltip-shadow)');

      if (data) {
        update(svg);
      }
    },
    [width, height, data, update],
  );

  return <D3 width="100%" onSetup={setup} onUpdate={update} />;
}

DayColumnsChart.propTypes = {
  /** The width as a proportion relative to the height. */
  width: PropTypes.number,
  /** The height as a proportion relative to the width. */
  height: PropTypes.number,

  /**
   * The data to display, assumed to be in chronological order.  Missing dates
   * are allowed, and are assumed to have a zero value.
   */
  data: PropTypes.arrayOf(
    PropTypes.shape({
      day: PropTypes.instanceOf(Date).isRequired,
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
};

DayColumnsChart.defaultProps = {
  width: 600,
  height: 200,
};
