import React from 'react';
import PropTypes from 'prop-types';
import { useFragment } from 'relay-hooks';
import graphql from 'babel-plugin-relay/macro';

import Nav from 'react-bootstrap/Nav';

import SearchDogsResultItem from './SearchDogsResultItem';

import DebugDump from '../components/DebugDump';

/**
 * The list of search results, showing each dog with a link, and highlighting
 * any matching terms.
 */
export default function SearchDogsResult({ results: resultsProp, resultLink }) {
  const results = useFragment(
    graphql`
      fragment SearchDogsResult_results on SearchDogsResultsConnection {
        nodes {
          dogId
          ...SearchDogsResultItem_item
        }
      }
    `,
    resultsProp,
  );
  if (!results || !results.nodes) {
    // console.warn('no nodes for SearchDogsResult');
    return null;
  }

  // console.log(`rendering search result (list): ${JSON.stringify(data)}`);

  return (
    <Nav className="flex-column">
      {results.nodes.map((item) => (
        <SearchDogsResultItem
          key={item.dogId}
          item={item}
          itemLink={resultLink}
        />
      ))}
      <DebugDump level={1} object={results} />
    </Nav>
  );
}

SearchDogsResult.propTypes = {
  /** fulfilled by Relay fragment */
  results: PropTypes.object,
  /** formatter for dog link (to customize the destination) */
  resultsLink: PropTypes.func,
};
