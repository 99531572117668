import React from 'react';
import PropTypes from 'prop-types';
import { useFragment } from 'relay-hooks';
import graphql from 'babel-plugin-relay/macro';

/**
 * search summary statistics (just a result count, really)
 */
export default function SearchStats({ results: resultsProp }) {
  const results = useFragment(
    graphql`
      fragment SearchStats_results on SearchDogsResultsConnection {
        totalCount
      }
    `,
    resultsProp,
  );

  // console.log(`rendering search result (list): ${JSON.stringify(data)}`);
  return (results ?? null) && <span>{results.totalCount} results</span>;
}

SearchStats.propTypes = {
  /** fulfilled by Relay fragment */
  results: PropTypes.object,
};
