/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type SearchDogsResult_results$ref = any;
type SearchPagination_results$ref = any;
type SearchStats_results$ref = any;
export type Sex = "F" | "M" | "%future added value";
export type Search_QueryVariables = {|
  query: string,
  sex?: ?Sex,
  offset: number,
  perPage: number,
|};
export type Search_QueryResponse = {|
  +searchResults: {|
    +$fragmentRefs: SearchStats_results$ref & SearchDogsResult_results$ref & SearchPagination_results$ref
  |}
|};
export type Search_Query = {|
  variables: Search_QueryVariables,
  response: Search_QueryResponse,
|};
*/


/*
query Search_Query(
  $query: String!
  $sex: Sex
  $offset: Int!
  $perPage: Int!
) {
  searchResults: searchDogs(query: $query, sex: $sex, offset: $offset, first: $perPage) {
    ...SearchStats_results
    ...SearchDogsResult_results
    ...SearchPagination_results
  }
}

fragment SearchDogsResultItem_item on SearchDogsResult {
  dogId
  registeredName
  callName
  registrations
  prefixes
  suffixes
  dog {
    titles {
      totalCount
    }
  }
  ...SearchDogsResultTitles_item
}

fragment SearchDogsResultTitles_item on SearchDogsResult {
  prefixes
  suffixes
}

fragment SearchDogsResult_results on SearchDogsResultsConnection {
  nodes {
    dogId
    ...SearchDogsResultItem_item
  }
}

fragment SearchPagination_results on SearchDogsResultsConnection {
  totalCount
}

fragment SearchStats_results on SearchDogsResultsConnection {
  totalCount
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "offset"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "perPage"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "query"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sex"
},
v4 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "perPage"
  },
  {
    "kind": "Variable",
    "name": "offset",
    "variableName": "offset"
  },
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "query"
  },
  {
    "kind": "Variable",
    "name": "sex",
    "variableName": "sex"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "Search_Query",
    "selections": [
      {
        "alias": "searchResults",
        "args": (v4/*: any*/),
        "concreteType": "SearchDogsResultsConnection",
        "kind": "LinkedField",
        "name": "searchDogs",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SearchStats_results"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SearchDogsResult_results"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SearchPagination_results"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "Search_Query",
    "selections": [
      {
        "alias": "searchResults",
        "args": (v4/*: any*/),
        "concreteType": "SearchDogsResultsConnection",
        "kind": "LinkedField",
        "name": "searchDogs",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SearchDogsResult",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dogId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "registeredName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "callName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "registrations",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "prefixes",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "suffixes",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Dog",
                "kind": "LinkedField",
                "name": "dog",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TitlesConnection",
                    "kind": "LinkedField",
                    "name": "titles",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7f519c68089c93c9410b57248d58dac4",
    "id": null,
    "metadata": {},
    "name": "Search_Query",
    "operationKind": "query",
    "text": "query Search_Query(\n  $query: String!\n  $sex: Sex\n  $offset: Int!\n  $perPage: Int!\n) {\n  searchResults: searchDogs(query: $query, sex: $sex, offset: $offset, first: $perPage) {\n    ...SearchStats_results\n    ...SearchDogsResult_results\n    ...SearchPagination_results\n  }\n}\n\nfragment SearchDogsResultItem_item on SearchDogsResult {\n  dogId\n  registeredName\n  callName\n  registrations\n  prefixes\n  suffixes\n  dog {\n    titles {\n      totalCount\n    }\n  }\n  ...SearchDogsResultTitles_item\n}\n\nfragment SearchDogsResultTitles_item on SearchDogsResult {\n  prefixes\n  suffixes\n}\n\nfragment SearchDogsResult_results on SearchDogsResultsConnection {\n  nodes {\n    dogId\n    ...SearchDogsResultItem_item\n  }\n}\n\nfragment SearchPagination_results on SearchDogsResultsConnection {\n  totalCount\n}\n\nfragment SearchStats_results on SearchDogsResultsConnection {\n  totalCount\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a2fc390e1af1a97633504e43f1cfd552';

module.exports = node;
