import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFragment } from 'relay-hooks';
import graphql from 'babel-plugin-relay/macro';

import { Card, ListGroup } from 'react-bootstrap';

import DebugDump from '../components/DebugDump';

import DogLink from './DogLink';

/**
 * Displays a list of all breeedings/children for a given dog.
 */
export default function DogChildren({ dog: dogProp }) {
  const dog = useFragment(
    graphql`
      fragment DogChildren_dog on Dog {
        id
        children {
          nodes {
            id
            birthdate
            sireId
            damId
            ...DogLink_dog
            sire {
              ...DogLink_dog
            }
            dam {
              ...DogLink_dog
            }
          }
        }
      }
    `,
    dogProp,
  );

  // The children a sorted by birthdate, sire, and dam.  We need to break this
  // into concrete breedings...
  //   breedings = [{date, sire, dam, children: [child, ...]},...]
  const [breedings, setBreedings] = useState([]);

  useEffect(() => {
    const breedings = [];
    let currentBreeding = {};
    // group children by date/sire/dam
    dog.children.nodes.forEach((d) => {
      if (
        d.birthdate !== currentBreeding.date ||
        d.sireId !== currentBreeding.sireId ||
        d.damId !== currentBreeding.damId
      ) {
        // any time the date/sire/dam changes, we consider it a new breeding
        currentBreeding = {
          date: d.birthdate,
          sireId: d.sireId,
          damId: d.damId,
          sire: d.sire,
          dam: d.dam,
          children: [],
        };
        breedings.push(currentBreeding);
      }

      currentBreeding.children.push(d);
    });

    setBreedings(breedings);
  }, [dog]);

  return (
    <>
      {breedings.length === 0 ? (
        <p>
          <i>(No breedings / children)</i>
        </p>
      ) : (
        breedings.map((b, i) => {
          return (
            <Card key={`${b.date}-${b.sireId}-${b.damId}`} className="my-3">
              <Card.Header>
                <h5>
                  #{i + 1}: <DogLink dog={b.sire} /> &times;{' '}
                  <DogLink dog={b.dam} />
                </h5>
                <div>
                  <i>(whelped {b.date})</i>
                </div>
              </Card.Header>
              <ListGroup variant="flush">
                {b.children.map((d) => (
                  <ListGroup.Item key={d.id}>
                    <DogLink dog={d} />
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Card>
          );
        })
      )}
      <DebugDump level={1} object={{ dog, breedings }} />
    </>
  );
}

DogChildren.propTypes = {
  /** fulfilled by Relay fragment */
  dog: PropTypes.object,
};
