/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type Sex = "F" | "M" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type PedigreeTree_dog$ref: FragmentReference;
declare export opaque type PedigreeTree_dog$fragmentType: PedigreeTree_dog$ref;
export type PedigreeTree_dog = {|
  +id: string,
  +sex: ?Sex,
  +ancestors: {|
    +nodes: $ReadOnlyArray<{|
      +dog: ?{|
        +id: string,
        +registeredName: string,
        +sex: ?Sex,
        +prefixes: ?$ReadOnlyArray<?string>,
        +suffixes: ?$ReadOnlyArray<?string>,
        +birthdate: ?string,
        +damId: ?string,
        +sireId: ?string,
      |}
    |}>
  |},
  +$refType: PedigreeTree_dog$ref,
|};
export type PedigreeTree_dog$data = PedigreeTree_dog;
export type PedigreeTree_dog$key = {
  +$data?: PedigreeTree_dog$data,
  +$fragmentRefs: PedigreeTree_dog$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sex",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": 5,
      "kind": "LocalArgument",
      "name": "depth"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PedigreeTree_dog",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "depth",
          "variableName": "depth"
        }
      ],
      "concreteType": "AncestorResultsConnection",
      "kind": "LinkedField",
      "name": "ancestors",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AncestorResult",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Dog",
              "kind": "LinkedField",
              "name": "dog",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "registeredName",
                  "storageKey": null
                },
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "prefixes",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "suffixes",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "birthdate",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "damId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "sireId",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Dog",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '1cabd3d6f220e2a6def9448e1a22d023';

module.exports = node;
