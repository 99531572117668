/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type DogRegistrations_dog$ref: FragmentReference;
declare export opaque type DogRegistrations_dog$fragmentType: DogRegistrations_dog$ref;
export type DogRegistrations_dog = {|
  +registrations: ?$ReadOnlyArray<?{|
    +registry: ?string,
    +registration: ?string,
  |}>,
  +$refType: DogRegistrations_dog$ref,
|};
export type DogRegistrations_dog$data = DogRegistrations_dog;
export type DogRegistrations_dog$key = {
  +$data?: DogRegistrations_dog$data,
  +$fragmentRefs: DogRegistrations_dog$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DogRegistrations_dog",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "RegistrationOutput",
      "kind": "LinkedField",
      "name": "registrations",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "registry",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "registration",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Dog",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '155a2b2e09557da3172498547cb04fd4';

module.exports = node;
