/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type DogTests_dog$ref: FragmentReference;
declare export opaque type DogTests_dog$fragmentType: DogTests_dog$ref;
export type DogTests_dog = {|
  +tests: ?$ReadOnlyArray<?{|
    +type: ?string,
    +result: ?string,
  |}>,
  +$refType: DogTests_dog$ref,
|};
export type DogTests_dog$data = DogTests_dog;
export type DogTests_dog$key = {
  +$data?: DogTests_dog$data,
  +$fragmentRefs: DogTests_dog$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DogTests_dog",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TestOutput",
      "kind": "LinkedField",
      "name": "tests",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "result",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Dog",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '28cea1eecb47c6fb1a7cc00d284e5268';

module.exports = node;
