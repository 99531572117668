/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Login_signin_QueryVariables = {|
  email: string,
  password: string,
|};
export type Login_signin_QueryResponse = {|
  +authenticate: ?string
|};
export type Login_signin_Query = {|
  variables: Login_signin_QueryVariables,
  response: Login_signin_QueryResponse,
|};
*/


/*
query Login_signin_Query(
  $email: String!
  $password: String!
) {
  authenticate(email: $email, password: $password)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "email"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "password"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      },
      {
        "kind": "Variable",
        "name": "password",
        "variableName": "password"
      }
    ],
    "kind": "ScalarField",
    "name": "authenticate",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Login_signin_Query",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Login_signin_Query",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "eb74ce155ffea1c6ad06e6c6f6ecb739",
    "id": null,
    "metadata": {},
    "name": "Login_signin_Query",
    "operationKind": "query",
    "text": "query Login_signin_Query(\n  $email: String!\n  $password: String!\n) {\n  authenticate(email: $email, password: $password)\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0fd059baed00eda30465a55db2374562';

module.exports = node;
