/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type App_currentUser_QueryVariables = {||};
export type App_currentUser_QueryResponse = {|
  +currentRole: ?string,
  +currentUser: ?{|
    +id: string,
    +firstName: string,
    +fullName: ?string,
    +gravatar: ?string,
  |},
|};
export type App_currentUser_Query = {|
  variables: App_currentUser_QueryVariables,
  response: App_currentUser_QueryResponse,
|};
*/


/*
query App_currentUser_Query {
  currentRole
  currentUser {
    id
    firstName
    fullName
    gravatar
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currentRole",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "currentUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "fullName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "gravatar",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "App_currentUser_Query",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "App_currentUser_Query",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "84aa6c84a2c0da4a5cfb87b0d57604c9",
    "id": null,
    "metadata": {},
    "name": "App_currentUser_Query",
    "operationKind": "query",
    "text": "query App_currentUser_Query {\n  currentRole\n  currentUser {\n    id\n    firstName\n    fullName\n    gravatar\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e1df2ec3b5f3614011b81aae80b96adf';

module.exports = node;
