/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type DogLink_dog$ref: FragmentReference;
declare export opaque type DogLink_dog$fragmentType: DogLink_dog$ref;
export type DogLink_dog = {|
  +id: string,
  +registeredName: string,
  +callName: ?string,
  +prefixes: ?$ReadOnlyArray<?string>,
  +suffixes: ?$ReadOnlyArray<?string>,
  +$refType: DogLink_dog$ref,
|};
export type DogLink_dog$data = DogLink_dog;
export type DogLink_dog$key = {
  +$data?: DogLink_dog$data,
  +$fragmentRefs: DogLink_dog$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DogLink_dog",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registeredName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "callName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "prefixes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "suffixes",
      "storageKey": null
    }
  ],
  "type": "Dog",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'a8764d37e6dc8d6316fdf4bba6903ddc';

module.exports = node;
