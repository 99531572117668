import React from 'react';
import PropTypes from 'prop-types';
import { useFragment } from 'relay-hooks';
import graphql from 'babel-plugin-relay/macro';

/**
 * The titles for a dog, either the prefix or suffix.
 */
export default function DogTitles({ dog: dogProp, side }) {
  const dog = useFragment(
    graphql`
      fragment DogTitles_dog on Dog {
        prefixes
        suffixes
      }
    `,
    dogProp,
  );

  const isPrefix = side === 'prefix';
  const titles = isPrefix ? dog?.prefixes : dog?.suffixes;

  // console.log(`rendering titles for (${side}): ${JSON.stringify(titles)}`);

  if (titles?.length === 0) {
    return null;
  }

  const padding = isPrefix ? 'mr-2' : 'ml-2';
  const classNames = `titles ${side} small text-muted ${padding}`;

  return <span className={classNames}>{titles?.join(' ')}</span>;
}

DogTitles.propTypes = {
  /** fulfilled by Relay fragment */
  dog: PropTypes.object,
  /** which set of titles to */
  side: PropTypes.oneOf(['prefix', 'suffix']),
};
