/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type DogRegistrations_dog$ref = any;
type DogTests_dog$ref = any;
export type Sex = "F" | "M" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type DogDetails_dog$ref: FragmentReference;
declare export opaque type DogDetails_dog$fragmentType: DogDetails_dog$ref;
export type DogDetails_dog = {|
  +callName: ?string,
  +sex: ?Sex,
  +birthdate: ?string,
  +color: ?string,
  +location: ?string,
  +zid: ?number,
  +fetchedAt: ?string,
  +importedAt: ?string,
  +$fragmentRefs: DogRegistrations_dog$ref & DogTests_dog$ref,
  +$refType: DogDetails_dog$ref,
|};
export type DogDetails_dog$data = DogDetails_dog;
export type DogDetails_dog$key = {
  +$data?: DogDetails_dog$data,
  +$fragmentRefs: DogDetails_dog$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DogDetails_dog",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "callName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sex",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "birthdate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "color",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "location",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "zid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fetchedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "importedAt",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DogRegistrations_dog"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DogTests_dog"
    }
  ],
  "type": "Dog",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '3adb616d590cd35d80c9ac5a1d566397';

module.exports = node;
