/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type DogTitles_dog$ref: FragmentReference;
declare export opaque type DogTitles_dog$fragmentType: DogTitles_dog$ref;
export type DogTitles_dog = {|
  +prefixes: ?$ReadOnlyArray<?string>,
  +suffixes: ?$ReadOnlyArray<?string>,
  +$refType: DogTitles_dog$ref,
|};
export type DogTitles_dog$data = DogTitles_dog;
export type DogTitles_dog$key = {
  +$data?: DogTitles_dog$data,
  +$fragmentRefs: DogTitles_dog$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DogTitles_dog",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "prefixes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "suffixes",
      "storageKey": null
    }
  ],
  "type": "Dog",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'e79e416068e5660ac6bc6e42ae38087f';

module.exports = node;
