import React, { useCallback, useContext, useEffect, useState } from 'react';
import { fetchQuery, commitMutation } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { useRelayEnvironment } from 'relay-hooks';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import { Alert, Button, Col, Container, Form, Row } from 'react-bootstrap';

import { Helmet } from 'react-helmet';

import { AppContext } from '../util/AppContext';
import DebugDump from '../components/DebugDump';

// The Login is used for logins, registration, and to reset passwords.
export default function Login({ onAuthChange }) {
  const environment = useRelayEnvironment();
  const { authToken } = useContext(AppContext);

  const [mode, setMode] = useState('login');
  const [authenticating, setAuthenticating] = useState(false);
  const [authInvalid, setAuthInvalid] = useState();
  const [authMessage, setAuthMessage] = useState();
  const [fields, setFields] = useState({
    email: '',
    password: '',
    verify: '',
  });
  const { email, password, verify } = fields;

  const [resetToken, setResetToken] = useState(null);
  const [enableEmail, setEnableEmail] = useState(true);
  const [showVerify, setShowVerify] = useState(false);
  const [showForgot, setShowForgot] = useState(true);
  const [showReset, setShowReset] = useState(false);
  const [sentReset, setSentReset] = useState(false);
  const [submitLabel, setSubmitLabel] = useState('Sign in');

  // Look for login/reset or login/register to change the fields shown...
  const history = useHistory();
  const location = useLocation();
  const resetMatch = useRouteMatch('/login/reset');
  const registerMatch = useRouteMatch('/login/register');

  const handleAuthResult = useCallback(
    (authToken, message) => {
      setAuthenticating(false);
      setAuthInvalid(!authToken);
      setAuthMessage(message);

      if (onAuthChange) {
        onAuthChange(authToken);
      }
    },
    [onAuthChange],
  );

  const handleFieldChange = useCallback((event) => {
    event.preventDefault();
    const { dataset, value } = event.currentTarget;
    const { field } = dataset;
    setFields((prev) => ({ ...prev, [field]: value }));
  }, []);

  const handleForgotPassword = useCallback((event) => {
    event.preventDefault();
    setShowReset(true);
  }, []);

  const handleSendPasswordReset = useCallback(
    async (event) => {
      event.preventDefault();
      setSentReset(true);

      const mutation = graphql`
        mutation Login_requestPasswordReset_Mutation(
          $input: RequestPasswordResetInput!
        ) {
          requestPasswordReset(input: $input) {
            clientMutationId
          }
        }
      `;

      const variables = { input: { email } };

      try {
        commitMutation(environment, { mutation, variables });
        console.log('password reset requested');
      } catch (error) {
        console.error('password reset requested', error);
      }
    },
    [environment, email],
  );

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();

      // We *used* to have an "Authorizer" class for this, but that seems overly
      // complicated, especially once we need to handle password reset and
      // registrations.
      setAuthenticating(true);

      switch (mode) {
        case 'login': {
          const query = graphql`
            query Login_signin_Query($email: String!, $password: String!) {
              authenticate(email: $email, password: $password)
            }
          `;

          const variables = { email, password };

          try {
            const response = await fetchQuery(environment, query, variables);
            const authToken = response.authenticate;
            const message = authToken
              ? ''
              : 'The “Email” or “Password” (or both) are incorrect.';
            handleAuthResult(authToken, message);
          } catch (error) {
            console.error(`LOGIN ERROR: ${JSON.stringify(error)}`);
            handleAuthResult(null);
          }

          break;
        }

        case 'reset': {
          // sanity check verify with password?
          if (verify !== password) {
            // TODO: We should display a message explaining the problem!
            handleAuthResult(
              null,
              'The “Verify” and “Password” fields must match.',
            );
            return;
          }

          const mutation = graphql`
            mutation Login_resetPassword_Mutation($input: ResetPasswordInput!) {
              resetPassword(input: $input) {
                jwt
              }
            }
          `;

          const variables = { input: { email, password, resetToken } };

          try {
            // not awaitable?! return value includes 'dispose' property!
            commitMutation(environment, {
              mutation,
              variables,
              onCompleted: (response) => {
                // TODO: a successful, but null response means that no password
                // change happened, probably because the token was expired.
                // We should display a message to that effect.
                const authToken = response.resetPassword.jwt;
                const message = authToken
                  ? undefined
                  : 'Unable to reset the password; you may need to request another password reset email.';
                handleAuthResult(authToken, message);
              },
              onError: (error) => {
                console.error('reset password', error);
                handleAuthResult(null);
              },
            });
          } catch (error) {
            console.error('reset password', error);
            handleAuthResult(null);
          }

          break;
        }

        // case 'register':
        //   break;

        default:
          // should not happen!
          console.error('unexpected login mode');
      }
    },
    [environment, mode, email, password, verify, resetToken, handleAuthResult],
  );

  // If we've authed, it's time to navigate out!
  useEffect(() => {
    if (authToken) {
      history.replace(location.state?.returnTo ?? '/');
    }
  }, [authToken, history, location]);

  // set the mode depending on the route/path
  useEffect(() => {
    if (resetMatch) {
      if (mode !== 'reset') {
        // console.log('setting RESET');
        const params = new URLSearchParams(location.search);

        setMode('reset');
        setFields((prev) => ({ ...prev, email: params.get('email') }));
        setResetToken(params.get('token'));
      }
    } else if (registerMatch) {
      if (mode !== 'register') {
        // console.log('setting REGISTER');
        console.warn('user-registration is NYI!');
      }
    } else if (mode !== 'login') {
      // console.log('setting LOGIN');
      setMode('login');
    }
  }, [mode, resetMatch, registerMatch, location]);

  useEffect(() => {
    switch (mode) {
      case 'login':
        setEnableEmail(true);
        setShowVerify(false);
        setShowForgot(true);
        setSubmitLabel('Sign in');
        break;
      case 'reset':
        setEnableEmail(false);
        setShowVerify(true);
        setShowForgot(false);
        setSubmitLabel('Reset password');
        break;
      default:
        console.warn(`unexpected mode? "${mode}"`);
        break;
    }
  }, [mode]);
  // console.warn('*** mode?', location);

  return (
    <Container className="mt-5">
      <Helmet title="Login" />
      <fieldset disabled={authenticating || sentReset}>
        <Row>
          <Col sm={{ offset: 3, span: 6 }}>
            <Form onSubmit={handleSubmit} className="mt-5 mb-4">
              <Form.Group as={Row} controlId="email">
                <Form.Label column sm={2}>
                  Email:
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    type="text"
                    placeholder="your_email@example.com"
                    value={email}
                    disabled={!enableEmail}
                    isInvalid={enableEmail && authInvalid}
                    onChange={handleFieldChange}
                    data-field="email"
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="password">
                <Form.Label column sm={2}>
                  Password:
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    type="password"
                    placeholder="password"
                    autoComplete="off"
                    value={password}
                    isInvalid={authInvalid}
                    onChange={handleFieldChange}
                    data-field="password"
                  />
                  <Form.Control.Feedback type="invalid">
                    {mode === 'login' && authMessage}
                  </Form.Control.Feedback>{' '}
                </Col>
              </Form.Group>
              {showVerify && (
                <Form.Group as={Row} controlId="verify">
                  <Form.Label column sm={2}>
                    Verify:
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="password"
                      placeholder="verify password"
                      autoComplete="off"
                      value={verify}
                      isInvalid={authInvalid}
                      onChange={handleFieldChange}
                      data-field="verify"
                    />
                    <Form.Control.Feedback type="invalid">
                      {mode === 'reset' && authMessage}
                    </Form.Control.Feedback>{' '}
                  </Col>
                </Form.Group>
              )}
              <Form.Group as={Row}>
                <Col sm={{ offset: 2 }}>
                  <Button type="submit" variant="primary">
                    {submitLabel}
                  </Button>
                </Col>
                {showForgot && (
                  <Col sm="auto">
                    <Button variant="link" onClick={handleForgotPassword}>
                      Forgot password?
                    </Button>
                  </Col>
                )}
              </Form.Group>
            </Form>
            {showReset && (
              <Alert variant="primary">
                <p>
                  To reset your password, please make sure the “Email” field
                  above has the email address you want to reset.
                </p>
                <p>
                  <Button
                    onClick={handleSendPasswordReset}
                    disabled={email.length === 0}
                  >
                    Send password reset mail
                  </Button>
                </p>
                {sentReset && (
                  <p>
                    Thank you! Please look for the email to <b>{email}</b>. It
                    might arrive in a “junk mail” folder.
                  </p>
                )}
              </Alert>
            )}
          </Col>
        </Row>
      </fieldset>

      <DebugDump level={1} object={location} />
    </Container>
  );
}
