import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

// import _ from 'lodash';
// import DebugDump from '../components/DebugDump';

// Takes a cue from https://github.com/mongodb-js/react-d3-unmanaged-wrapper
// but simplifies things even further.  Instead of requiring a particular
// idiomatic programming structure (a function that has particular properties
// for setup and update), this component takes properties to define those
// callbacks.

// I'm not sure that we really need "onUpdate"...?

// Also.... could this be more of a hook?  Like `useSvg()` or `useD3()`?  We
// might have to invert the logic, but it's potentially an interesting idea.
export default function D3({ width, height, onSetup, onUpdate }) {
  const svg = useRef(null);

  useEffect(() => {
    if (onSetup) {
      onSetup(svg.current);
    }
  }, [onSetup]);

  useEffect(() => {
    if (onUpdate) {
      onUpdate(svg.current);
    }
  });

  return <svg ref={svg} width={width} height={height} />;
}

D3.propTypes = {
  width: PropTypes.any, // .isRequired,
  height: PropTypes.any, // .isRequired,
  onSetup: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};
