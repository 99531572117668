import React from 'react';
// import PropTypes from 'prop-types';
import { useQuery } from 'relay-hooks';
import graphql from 'babel-plugin-relay/macro';

import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

import DataTable from '../../components/DataTable';
import DebugDump from '../../components/DebugDump';

export default function Registries() {
  const { props: queryResult, error: queryError } = useQuery(
    graphql`
      query Registries_Query {
        registries(orderBy: REGISTRY_ASC) {
          nodes {
            registry
            id
            description
            createdAt
            updatedAt
            registrations {
              totalCount
            }
          }
        }
      }
    `,
  );

  return (
    <>
      <Helmet title="Registries" />
      <Container className="mt-4">
        <h2 className="mb-3">Registries</h2>

        {queryError ? (
          <>
            <div>Error: {queryError.message}</div>
            <div>Source: {JSON.stringify(queryError.source)}</div>
          </>
        ) : !queryResult ? (
          <div>Loading...</div>
        ) : (
          <>
            <DataTable
              columns={[
                { label: 'Name', fn: (r) => r.registry },
                {
                  label: 'Registrations',
                  fn: (r) => r.registrations.totalCount,
                  className: 'text-right',
                },
              ]}
              standardExtraColumns
              data={queryResult?.registries?.nodes}
            />

            <DebugDump level={1} object={queryResult} />
          </>
        )}
      </Container>
    </>
  );
}
