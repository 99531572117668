import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// import _ from 'lodash';

import { AppContext } from '../util/AppContext';

const Pre = styled.pre.attrs({
  className: 'ml-4 p-1',
})`
  white-space: pre-wrap;
  font-size: 50%;
  background-color: #eee;
`;

/**
 * Displays the JSON for any given object, if the passed `level` is less than or
 * equal to the app-wide context `debug` level.
 */
export default function DebugDump({ level, object }) {
  const { debug } = useContext(AppContext);

  if (typeof debug !== 'number' || typeof level !== 'number' || debug < level) {
    return null;
  }

  return <Pre>{JSON.stringify(object, null, '    ')}</Pre>;
}

DebugDump.propTypes = {
  /** the level at which to show this object (shown if <= current)*/
  level: PropTypes.number,
  /** the object to show */
  object: PropTypes.object,
};

DebugDump.defaultProps = {
  level: 1,
};
