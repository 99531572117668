/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Profile_QueryVariables = {||};
export type Profile_QueryResponse = {|
  +profile: ?{|
    +id: string,
    +createdAt: string,
    +updatedAt: string,
    +firstName: string,
    +lastName: string,
    +email: ?string,
    +gravatar: ?string,
    +role: ?string,
  |}
|};
export type Profile_Query = {|
  variables: Profile_QueryVariables,
  response: Profile_QueryResponse,
|};
*/


/*
query Profile_Query {
  profile: currentUser {
    id
    createdAt
    updatedAt
    firstName
    lastName
    email
    gravatar
    role
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": "profile",
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "currentUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "updatedAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "gravatar",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "role",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "Profile_Query",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "Profile_Query",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "382f47f77e88dd2df8a2034e3a0ad178",
    "id": null,
    "metadata": {},
    "name": "Profile_Query",
    "operationKind": "query",
    "text": "query Profile_Query {\n  profile: currentUser {\n    id\n    createdAt\n    updatedAt\n    firstName\n    lastName\n    email\n    gravatar\n    role\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7d5312a01b0274e0aa1ff5ca6b81b7bf';

module.exports = node;
