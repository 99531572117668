/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type PedigreeTree_dog$ref = any;
export type Pedigree_QueryVariables = {|
  id: string,
  depth: number,
|};
export type Pedigree_QueryResponse = {|
  +dog: ?{|
    +$fragmentRefs: PedigreeTree_dog$ref
  |}
|};
export type Pedigree_Query = {|
  variables: Pedigree_QueryVariables,
  response: Pedigree_QueryResponse,
|};
*/


/*
query Pedigree_Query(
  $id: Id!
  $depth: Int!
) {
  dog(id: $id) {
    ...PedigreeTree_dog_1gqN78
  }
}

fragment PedigreeTree_dog_1gqN78 on Dog {
  id
  sex
  ancestors(depth: $depth) {
    nodes {
      dog {
        id
        registeredName
        sex
        prefixes
        suffixes
        birthdate
        damId
        sireId
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "depth"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v3 = [
  {
    "kind": "Variable",
    "name": "depth",
    "variableName": "depth"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sex",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "Pedigree_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Dog",
        "kind": "LinkedField",
        "name": "dog",
        "plural": false,
        "selections": [
          {
            "args": (v3/*: any*/),
            "kind": "FragmentSpread",
            "name": "PedigreeTree_dog"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "Pedigree_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Dog",
        "kind": "LinkedField",
        "name": "dog",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "AncestorResultsConnection",
            "kind": "LinkedField",
            "name": "ancestors",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AncestorResult",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Dog",
                    "kind": "LinkedField",
                    "name": "dog",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "registeredName",
                        "storageKey": null
                      },
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "prefixes",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "suffixes",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "birthdate",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "damId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "sireId",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "90d44789f851251a6b1f99ed50cf1f1f",
    "id": null,
    "metadata": {},
    "name": "Pedigree_Query",
    "operationKind": "query",
    "text": "query Pedigree_Query(\n  $id: Id!\n  $depth: Int!\n) {\n  dog(id: $id) {\n    ...PedigreeTree_dog_1gqN78\n  }\n}\n\nfragment PedigreeTree_dog_1gqN78 on Dog {\n  id\n  sex\n  ancestors(depth: $depth) {\n    nodes {\n      dog {\n        id\n        registeredName\n        sex\n        prefixes\n        suffixes\n        birthdate\n        damId\n        sireId\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '70252113d1f2b4a82b423b7442b80205';

module.exports = node;
