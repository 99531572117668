/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type DogDetails_dog$ref = any;
export type Details_QueryVariables = {|
  id: string
|};
export type Details_QueryResponse = {|
  +dog: ?{|
    +$fragmentRefs: DogDetails_dog$ref
  |}
|};
export type Details_Query = {|
  variables: Details_QueryVariables,
  response: Details_QueryResponse,
|};
*/


/*
query Details_Query(
  $id: Id!
) {
  dog(id: $id) {
    ...DogDetails_dog
  }
}

fragment DogDetails_dog on Dog {
  callName
  sex
  birthdate
  color
  location
  ...DogRegistrations_dog
  ...DogTests_dog
  zid
  fetchedAt
  importedAt
}

fragment DogRegistrations_dog on Dog {
  registrations {
    registry
    registration
  }
}

fragment DogTests_dog on Dog {
  tests {
    type
    result
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Details_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Dog",
        "kind": "LinkedField",
        "name": "dog",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DogDetails_dog"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Details_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Dog",
        "kind": "LinkedField",
        "name": "dog",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "callName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sex",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "birthdate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "color",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "location",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "RegistrationOutput",
            "kind": "LinkedField",
            "name": "registrations",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "registry",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "registration",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TestOutput",
            "kind": "LinkedField",
            "name": "tests",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "result",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "zid",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fetchedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "importedAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "455a2ea2886f39afe8d34a2b09ade32b",
    "id": null,
    "metadata": {},
    "name": "Details_Query",
    "operationKind": "query",
    "text": "query Details_Query(\n  $id: Id!\n) {\n  dog(id: $id) {\n    ...DogDetails_dog\n  }\n}\n\nfragment DogDetails_dog on Dog {\n  callName\n  sex\n  birthdate\n  color\n  location\n  ...DogRegistrations_dog\n  ...DogTests_dog\n  zid\n  fetchedAt\n  importedAt\n}\n\nfragment DogRegistrations_dog on Dog {\n  registrations {\n    registry\n    registration\n  }\n}\n\nfragment DogTests_dog on Dog {\n  tests {\n    type\n    result\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5dd695965aed171c83bb0872b746afc3';

module.exports = node;
