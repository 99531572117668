import React from 'react';

// import { withAppContext } from '../util/AppContext';

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

export default function Kennels() {
  return (
    <Container className="mt-4">
      <Row>
        <Col>
          <h1>Kennels</h1>
        </Col>
      </Row>
    </Container>
  );
}
