/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteDogInput = {|
  clientMutationId?: ?string,
  id: string,
|};
export type Maintenance_deleteDog_MutationVariables = {|
  input: DeleteDogInput
|};
export type Maintenance_deleteDog_MutationResponse = {|
  +deleteDog: ?{|
    +clientMutationId: ?string
  |}
|};
export type Maintenance_deleteDog_Mutation = {|
  variables: Maintenance_deleteDog_MutationVariables,
  response: Maintenance_deleteDog_MutationResponse,
|};
*/


/*
mutation Maintenance_deleteDog_Mutation(
  $input: DeleteDogInput!
) {
  deleteDog(input: $input) {
    clientMutationId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteDogPayload",
    "kind": "LinkedField",
    "name": "deleteDog",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Maintenance_deleteDog_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Maintenance_deleteDog_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ecf623e6ecffcde42b7e30f694b9ebe4",
    "id": null,
    "metadata": {},
    "name": "Maintenance_deleteDog_Mutation",
    "operationKind": "mutation",
    "text": "mutation Maintenance_deleteDog_Mutation(\n  $input: DeleteDogInput!\n) {\n  deleteDog(input: $input) {\n    clientMutationId\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '52cd06dc3aae2ac9107d023dfb2ed28e';

module.exports = node;
