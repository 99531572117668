import React from 'react';
import PropTypes from 'prop-types';
import { useFragment } from 'relay-hooks';
import graphql from 'babel-plugin-relay/macro';

/**
 * The test row (for DogDetails) for a given dog.
 */
export default function DogTests({ dog: dogProp }) {
  const dog = useFragment(
    graphql`
      fragment DogTests_dog on Dog {
        tests {
          type
          result
        }
      }
    `,
    dogProp,
  );

  if (!dog.tests || dog.tests.length === 0) {
    return null;
  }

  return (
    <tr>
      <th scope="row">tests:</th>
      <td>{dog.tests.map((t) => `${t.type}: ${t.result}`).join(', ')}</td>
    </tr>
  );
}

DogTests.propTypes = {
  /** fulfilled by Relay fragment */
  dog: PropTypes.object,
};
