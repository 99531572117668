import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'relay-hooks';
import { useHistory, useLocation } from 'react-router-dom';
import graphql from 'babel-plugin-relay/macro';

import { processQuery } from '../search/query';

import SimpleSearchBox from '../components/SimpleSearchBox';
import SearchStats from './SearchStats';
import SearchDogsResult from './SearchDogsResult';
import SearchPagination from './SearchPagination';

import _ from 'lodash';
import querystring from 'querystring';

import DebugDump from '../components/DebugDump';

function extractLocationParams(location) {
  const qs = location.search.slice(1);
  const params = querystring.decode(qs);

  const query = (params.q || '').trim();

  let page = parseInt(params.p, 10) || 1;

  // handle very, very weird NaN edge cases...
  if (!_.isFinite(page)) {
    page = 1;
  }

  let debug = parseInt(params.debug, 10) || 0;
  if (!_.isFinite(debug)) {
    debug = 0;
  }

  return {
    query,
    page,
    debug,
  };
}

// The Search component encapsulates a general-purpose dog search, so that the
// backend-specific query processing only has to exist in one place.  It does
// make the assumption that there's a `q` querystring parameter that carries the
// query.  (This could be exposed as a prop!)
//
// If no query is present, the Search's children are shown instead.
export default function Search({ children, sex, resultLink, onChange }) {
  const location = useLocation();
  const history = useHistory();

  // always, or in useEffect?
  const {
    query: queryFromLocation,
    page: pageFromLocation /* , debug */,
  } = extractLocationParams(location);

  // In order to get queries to auto-load on navigation (routing), we seed
  // them in as an `initialQuery` property on the search box.
  const initialQuery = useRef(queryFromLocation);
  const perPage = 10;
  const [query, setQuery] = useState(queryFromLocation);
  const [page, setPage] = useState(pageFromLocation);
  const [processedQuery, setProcessedQuery] = useState();

  useEffect(() => {
    setPage(1);
    setProcessedQuery(processQuery(query));
  }, [query]);

  useEffect(() => {
    history.push({
      search: !query || query === '' ? '' : `?q=${query}&p=${page}`,
    });
  }, [history, query, page]);

  useEffect(() => {
    if (onChange) {
      onChange(query, page);
    }
  }, [query, page, onChange]);

  const { props: queryResults, error: queryError } = useQuery(
    graphql`
      query Search_Query(
        $query: String!
        $sex: Sex
        $offset: Int!
        $perPage: Int!
      ) {
        searchResults: searchDogs(
          query: $query
          sex: $sex
          offset: $offset
          first: $perPage
        ) {
          ...SearchStats_results
          ...SearchDogsResult_results
          ...SearchPagination_results
        }
      }
    `,
    {
      query: processedQuery,
      sex,
      offset: (page - 1) * perPage,
      perPage: perPage,
    },
  );

  // We use a cached result set to avoid flickering to the "loading" message
  // while typing.  This is totally wrong according to the React model, because
  // we should make this a state value.  However, Relay forces to to handle the
  // GraphQL response *in the render*, from which you are not supposed to set
  // state!
  //
  // NOTE: this *might* be acceptable as state with hooks... or maybe we don't
  // need any once we use the relay hooks?
  const lastResults = useRef();

  const searchResults = queryResults?.searchResults;
  if (searchResults) {
    lastResults.current = searchResults;
  }

  return (
    <>
      <SimpleSearchBox
        initialQuery={initialQuery.current}
        onChange={setQuery}
      />

      {!query || query === '' ? (
        <>{children}</>
      ) : (
        <>
          {queryError ? (
            <>
              <div>Error: {queryError.message}</div>
              <div>Source: {JSON.stringify(queryError.source)}</div>
            </>
          ) : !lastResults.current ? (
            <div>Loading...</div>
          ) : (
            <>
              <div className="ml-2 text-right">
                <SearchStats results={lastResults.current} />
              </div>
              <SearchDogsResult
                results={lastResults.current}
                resultLink={resultLink}
              />
              <SearchPagination
                query={query}
                results={lastResults.current}
                currentPage={page}
                perPage={perPage}
                onPage={setPage}
              />
            </>
          )}
        </>
      )}

      <DebugDump
        level={1}
        object={{
          initialQuery: initialQuery.current,
          query,
          page,
          processedQuery,
          queryError,
          queryResults,
        }}
      />
    </>
  );
}

Search.propTypes = {
  /** Any children are rendered when there is no query */
  children: PropTypes.node,
  /** used as an optional filter to restrict the results */
  sex: PropTypes.string,
  /** a link-formatter/handler */
  resultLink: PropTypes.func,
  /** called when the query or page changes */
  onChange: PropTypes.func,
};

// function padding(depth) {
//     return '  '.repeat(depth);
// }
