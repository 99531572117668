import React from 'react';

import { Route, Switch } from 'react-router-dom';

import Changelog from './Changelog';
import Colors from './Colors';
import Locations from './Locations';
import Maintenance from './Maintenance';
import Registries from './Registries';
import Statistics from './Statistics';
import TradingCards from './TradingCards';
import Users from './Users';

export default function Admin() {
  return (
    <Switch>
      <Route path="/admin/changelog" component={Changelog} />
      <Route path="/admin/colors" component={Colors} />
      <Route path="/admin/locations" component={Locations} />
      <Route path="/admin/maintenance" component={Maintenance} />
      <Route path="/admin/registries" component={Registries} />
      <Route path="/admin/statistics" component={Statistics} />
      <Route path="/admin/tradingcards" component={TradingCards} />
      <Route path="/admin/users" component={Users} />
    </Switch>
  );
}
