import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { useQuery } from 'relay-hooks';
import graphql from 'babel-plugin-relay/macro';

import { NavLink, Redirect, Route, Switch, useParams } from 'react-router-dom';

import { Container, Nav } from 'react-bootstrap';

import smartquotes from 'smartquotes';

import { Helmet } from 'react-helmet';

import DogTitles from '../../dogs/DogTitles';

import DogContext from './DogContext';

import Breedings from './Breedings';
import Details from './Details';
import Pedigree from './Pedigree';
import ReversePedigree from './ReversePedigree';
import PedigreeExplorer from './PedigreeExplorer';

// import DebugDump from '../components/DebugDump';

export default function Dogs() {
  const { id } = useParams();
  const [dog, setDog] = useState();
  const [prettyName, setPrettyName] = useState('...');
  const [hasTitles, setHasTitles] = useState();
  const [linkBase, setLinkBase] = useState();
  const [context, setContext] = useState({});

  const {
    props: queryResult,
    error: queryError,
    // cached: queryCached,
  } = useQuery(
    graphql`
      query Dogs_Query($id: Id!) {
        dog(id: $id) {
          id
          registeredName
          sex
          prefixes
          suffixes
          ...DogTitles_dog
        }
      }
    `,
    { id },
  );

  useEffect(() => {
    const queryDog = queryResult?.dog;

    if (queryDog?.id && queryDog.id !== dog?.id) {
      setDog(queryDog);
      setPrettyName(smartquotes(queryDog.registeredName));
      setHasTitles(
        queryDog.prefixes?.length > 0 || queryDog.suffixes?.length > 0,
      );
      const linkBase = `/dogs/${queryDog.id}`;
      setLinkBase(linkBase);

      setContext({ ...queryDog, linkBase });
    }
  }, [queryResult, dog]);

  return (
    <>
      {queryError ? (
        <>
          <div>Error: {queryError.message}</div>
          <div>Source: {JSON.stringify(queryError.source)}</div>
        </>
      ) : !dog ? (
        <div>Loading...</div>
      ) : (
        <DogContext.Provider value={context}>
          <Helmet title={prettyName} />
          <Container className="mt-4">
            <h1>{prettyName}</h1>
            {hasTitles && (
              <p className="lead">
                <DogTitles side="prefix" dog={dog} />
                <span className="name">{prettyName}</span>
                <DogTitles side="suffix" dog={dog} />
              </p>
            )}

            <Nav variant="pills" justify className="mb-4">
              <Nav.Item>
                <Nav.Link as={NavLink} to={linkBase} exact>
                  Details
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={NavLink} to={`${linkBase}/pedigree`}>
                  Pedigree
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={NavLink} to={`${linkBase}/breedings`}>
                  Breedings
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={NavLink} to={`${linkBase}/reverse`}>
                  Reverse Pedigree
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={NavLink} to={`${linkBase}/explore`}>
                  Pedigree Explorer
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Container>
          <Switch>
            <Route path="/dogs/:id" exact component={Details} />
            <Route path="/dogs/:id/pedigree" component={Pedigree} />
            <Route path="/dogs/:id/breedings" component={Breedings} />
            <Route path="/dogs/:id/reverse" component={ReversePedigree} />
            <Route path="/dogs/:id/explore" component={PedigreeExplorer} />
            <Route render={() => <Redirect to={linkBase} />} />
          </Switch>{' '}
        </DogContext.Provider>
      )}
    </>
  );
}
