/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Maintenance_QueryVariables = {|
  until?: ?string
|};
export type Maintenance_QueryResponse = {|
  +findDuplicates: {|
    +totalCount: number,
    +nodes: $ReadOnlyArray<{|
      +zid: ?number,
      +count: ?number,
      +ids: ?$ReadOnlyArray<?string>,
      +registeredNames: ?$ReadOnlyArray<?string>,
      +birthdates: ?$ReadOnlyArray<?string>,
      +fetchedAt: ?$ReadOnlyArray<?string>,
      +importedAt: ?$ReadOnlyArray<?string>,
    |}>,
  |},
  +leastRecentFetchedDogs: {|
    +nodes: $ReadOnlyArray<{|
      +registeredName: string,
      +id: string,
      +zid: ?number,
      +fetchedAt: ?string,
      +importedAt: ?string,
    |}>
  |},
  +olderThanXDays: {|
    +nodes: $ReadOnlyArray<{|
      +zid: ?number
    |}>
  |},
|};
export type Maintenance_Query = {|
  variables: Maintenance_QueryVariables,
  response: Maintenance_QueryResponse,
|};
*/


/*
query Maintenance_Query(
  $until: Datetime
) {
  findDuplicates {
    totalCount
    nodes {
      zid
      count
      ids
      registeredNames
      birthdates
      fetchedAt
      importedAt
    }
  }
  leastRecentFetchedDogs(first: 10) {
    nodes {
      registeredName
      id
      zid
      fetchedAt
      importedAt
    }
  }
  olderThanXDays: leastRecentFetchedDogs(until: $until) {
    nodes {
      zid
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "until"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "zid",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fetchedAt",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "importedAt",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "DuplicateDogsConnection",
    "kind": "LinkedField",
    "name": "findDuplicates",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "DuplicateDog",
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "count",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ids",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "registeredNames",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "birthdates",
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "first",
        "value": 10
      }
    ],
    "concreteType": "DogsConnection",
    "kind": "LinkedField",
    "name": "leastRecentFetchedDogs",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Dog",
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "registeredName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": "leastRecentFetchedDogs(first:10)"
  },
  {
    "alias": "olderThanXDays",
    "args": [
      {
        "kind": "Variable",
        "name": "until",
        "variableName": "until"
      }
    ],
    "concreteType": "DogsConnection",
    "kind": "LinkedField",
    "name": "leastRecentFetchedDogs",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Dog",
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Maintenance_Query",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Maintenance_Query",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "77ee23fa6e7d1ec19eb0fac38c58075d",
    "id": null,
    "metadata": {},
    "name": "Maintenance_Query",
    "operationKind": "query",
    "text": "query Maintenance_Query(\n  $until: Datetime\n) {\n  findDuplicates {\n    totalCount\n    nodes {\n      zid\n      count\n      ids\n      registeredNames\n      birthdates\n      fetchedAt\n      importedAt\n    }\n  }\n  leastRecentFetchedDogs(first: 10) {\n    nodes {\n      registeredName\n      id\n      zid\n      fetchedAt\n      importedAt\n    }\n  }\n  olderThanXDays: leastRecentFetchedDogs(until: $until) {\n    nodes {\n      zid\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd9eddc301e8034f85e5785181610d37b';

module.exports = node;
