/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type DogLink_dog$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type DogChildren_dog$ref: FragmentReference;
declare export opaque type DogChildren_dog$fragmentType: DogChildren_dog$ref;
export type DogChildren_dog = {|
  +id: string,
  +children: {|
    +nodes: $ReadOnlyArray<{|
      +id: string,
      +birthdate: ?string,
      +sireId: ?string,
      +damId: ?string,
      +sire: ?{|
        +$fragmentRefs: DogLink_dog$ref
      |},
      +dam: ?{|
        +$fragmentRefs: DogLink_dog$ref
      |},
      +$fragmentRefs: DogLink_dog$ref,
    |}>
  |},
  +$refType: DogChildren_dog$ref,
|};
export type DogChildren_dog$data = DogChildren_dog;
export type DogChildren_dog$key = {
  +$data?: DogChildren_dog$data,
  +$fragmentRefs: DogChildren_dog$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "DogLink_dog"
},
v2 = [
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DogChildren_dog",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "DogsConnection",
      "kind": "LinkedField",
      "name": "children",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Dog",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "birthdate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sireId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "damId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Dog",
              "kind": "LinkedField",
              "name": "sire",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Dog",
              "kind": "LinkedField",
              "name": "dam",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": null
            },
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Dog",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '6f3ca7510822cd7d05b491ef052380c4';

module.exports = node;
